import { CurrencyDollarIcon, PlusIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { StepperEventos } from "./SteperEventos";
import Step1 from "./StepsCreate/Step1";
import EventoContext from "../../../context/EventoContext";
import Step2 from "./StepsCreate/Step2";
import Step3 from "./StepsCreate/Step3";
import axios from "axios";
import Step4 from "./StepsCreate/Step4";
import urlApi from "../../../urlApi";

export default function DialogCreateEventos() {
  const [open, setOpen] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [image, setImage] = useState();
  const [tituloEvento, setTituloEvento] = useState();
  const [nomeContratante, setNomeContratante] = useState();
  const [nomeAniversariante, setNomeAniversariante] = useState("");
  const [descricao, setDescricao] = useState("");
  const [idadeAniversariante, setIdadeAniversariante] = useState("");
  const [horarioDoBolo, setHorarioDoBolo] = useState("");
  const [endereco, setEndereco] = useState();
  const [telefoneContato, setTelefoneContato] = useState();
  const [horarioElenco, setHorarioElenco] = useState();
  const [isLastStep, setIsLastStep] = useState(true);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [horarioFinal, setHorarioFinal] = useState();
  const [selectedFantasia, setSelectedFantasia] = useState([]);
  const [itemsFantasia, setItemsFantasia] = useState([]);
  const [selectedColab, setSelectedColab] = useState([]);
  const [colabData, setColabData] = useState([]);
  const [itemsColab, setItemsColab] = useState([]);
  const [pagamento, setPagamento] = useState();
  const [descricaoPagamento, setDescricaoPagamento] = useState("");
  

  const handleOpen = () => setOpen(!open);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleImage = (imagem) => {
    setImage(imagem);
  };
  const handleTituloEventoChange = (event) => {
    setTituloEvento(event.target.value);
  };

  const handleNomeContratanteChange = (event) => {
    setNomeContratante(event.target.value);
  };

  const handleEnderecoChange = (event) => {
    setEndereco(event.target.value);
  };

  const handleTelefoneContatoChange = (event) => {
    setTelefoneContato(event.target.value);
  };

  const handleHorarioElencoChange = (event) => {
    setHorarioElenco(event.target.value);
  };
  const handleHorarioFinalChange = (event) => {
    setHorarioFinal(event.target.value);
  };
  const handleNomeAniversarianteChange = (event) => {
    setNomeAniversariante(event.target.value);
  };

  const handleDescricaoChange = (event) => {
    setDescricao(event.target.value);
  };

  const handleIdadeAniversarianteChange = (event) => {
    setIdadeAniversariante(event.target.value);
  };

  const handleHorarioDoBoloChange = (event) => {
    setHorarioDoBolo(event.target.value);
  };

  const handlePagamento = (event) => {
    setPagamento(event.target.value);
  };

  const handleDescricaoPagamento = (event) => {
    setDescricaoPagamento(event.target.value);
  };

  useEffect(() => {
    if (activeStep == 0) {
      if (
        tituloEvento &&
        nomeContratante &&
        endereco &&
        telefoneContato &&
        horarioElenco &&
        horarioFinal
      ) {
        setIsLastStep(false);
      } else {
        setIsLastStep(true);
      }
    }
    if (activeStep === 1) {
      setIsLastStep(false);
    }

    if (activeStep === 2) {
      if (selectedFantasia.length > 0) {
        setIsLastStep(false);
      } else {
        setIsLastStep(true);
      }
    }
    if (activeStep === 3) {
      if (selectedColab.length > 0) {
        setIsLastStep(false);
      } else {
        setIsLastStep(true);
      }
    }
  }, [
    image,
    tituloEvento,
    nomeContratante,
    endereco,
    telefoneContato,
    horarioElenco,
    activeStep,
    selectedColab,
    selectedFantasia,
  ]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        `${urlApi}/allFantasias`
      );
      const responseColab = await axios.get(
        `${urlApi}/allColaboradores`
      );
      setItemsColab(responseColab.data);
      setItemsFantasia(response.data);
    }
    fetchData();
    return () => {
      setSelectedFantasia([]);
      setSelectedColab([]);
    };
  }, []);

  return (
    <EventoContext.Provider
      value={{
        handleDateChange,
        handleImage,
        handleTituloEventoChange,
        handleNomeContratanteChange,
        handleEnderecoChange,
        handleTelefoneContatoChange,
        handleHorarioFinalChange,
        handleHorarioElencoChange,
        handleNomeAniversarianteChange,
        handleIdadeAniversarianteChange,
        handleHorarioDoBoloChange,
        handleDescricaoChange,
        handleDescricaoPagamento,
        setIsLastStep,
        setIsFirstStep,
        setUploadedImage,
        setActiveStep,
        setSelectedFantasia,
        setOpen,
        setItemsFantasia,
        setSelectedColab,
        setItemsColab,
        setColabData,
        handlePagamento,
        descricaoPagamento,
        pagamento,
        colabData,
        itemsColab,
        selectedColab,
        itemsFantasia,
        selectedFantasia,
        idadeAniversariante,
        horarioDoBolo,
        descricao,
        nomeAniversariante,
        horarioFinal,
        activeStep,
        selectedDate,
        uploadedImage,
        isLastStep,
        isFirstStep,
        tituloEvento,
        nomeContratante,
        endereco,
        image,
        telefoneContato,
        horarioElenco,
      }}
    >
      <div className="hidden sm:hidden lg:flex" onClick={handleOpen}>
        <Button className="flex items-center gap-3" color="orange" size="sm">
          <PlusIcon strokeWidth={2} className="h-4 w-4" /> Adicionar Evento
        </Button>
      </div>
      <div className="block lg:hidden sm:flex" onClick={handleOpen}>
        <Button className="flex items-center gap-3" color="orange" size="sm">
          <PlusIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </div>
      {open && (
        <Dialog
          className="bg-[#1e1e1e] "
          open={open}
          size={"lg"}
          handler={handleOpen}
        >
          <DialogHeader className="text-white opacity-80 overflow-auto justify-between">
            Cadastrar evento
            <div className="items-center">
              <Input
                required
                label="Valor a ser pago"
                onChange={handlePagamento}
                value={pagamento}
                className="text-white"
                color="orange"
                type="number"
                icon={
                  <CurrencyDollarIcon/>
                }
              />
            </div>
          </DialogHeader>
          <DialogBody
            divider
            className=" flex flex-col gap-5 max-h-[400px] overflow-y-auto"
          >
            {activeStep === 0 ? <Step1 /> : null}
            {activeStep === 1 ? <Step2 /> : null}
            {activeStep === 2 ? <Step3 /> : null}
            {activeStep === 3 ? <Step4 /> : null}
          </DialogBody>
          <DialogFooter>
            <StepperEventos />
          </DialogFooter>
        </Dialog>
      )}
    </EventoContext.Provider>
  );
}
