import axios from "axios";
import urlApi from "../../urlApi";

async function DeleteAFandamento(id,idEvento, isAtor){
    try{
        const response = await axios.delete(`${urlApi}/deleteAFandamento/${id}/${idEvento}/${isAtor}`);

    }catch(error){
        console.log(error);
    }
}

export default DeleteAFandamento;