import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import axios from "axios";
import { Spinner, Typography } from "@material-tailwind/react";
import { Paginacao } from "../Home/components/Paginacao";
import { CardsEventos } from "../Home/components/CardsEventos";
import urlApi from "../../urlApi";

export default function EventosFinalizados() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento
  const [pesquisar, setPesquisar] = useState("");

  const fetchData = async (pagina) => {
    try {
      const response = await axios.get(
        `${urlApi}/eventosConcluidos/${pagina}`
      );
      setData(response.data);
      response.status === 200 ? setLoading(false) : setLoading(true);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  useEffect(() => {
    const filterData = async () => {
      try {
        const response = await axios.get(
          `${urlApi}/searchEventosConcluidos/${pesquisar}`
        );
        setData(response.data);
        response.status === 200 ? setLoading(false) : setLoading(true);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    filterData();
  }, [pesquisar]);

  return (
    <>
      <div className="min-h-full">
        <header>
          <div className="font-[Source Sans 3] mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex justify-between">
            <div className="relative flex items-center lg:w-64 sm:w-72 rounded bg-[#404040] h-[40px]">
              <input
                onChange={(event) => setPesquisar(event.target.value)}
                type="text"
                id="pesquisar"
                placeholder="Pesquisar"
                className="bg-[#404040] placeholder:text-zinc-500 placeholder:text-[17px] placeholder:font-medium text-white p-2 px-5  w-full focus:outline-none"
              />
              <MagnifyingGlassIcon className="h-7 w-7 mr-2 text-white" />
            </div>
            <div className="flex flex-col gap-2 justify-end">
              <Typography
                color="white"
                variant="h6"
                className="flex justify-end"
              >
                Total de eventos: {data["totalEventos"]}
              </Typography>
            </div>
          </div>
        </header>
        <main>
          {loading ? (
            <div className="flex justify-center">
              <Spinner color="amber" className="h-12 w-12 " />
            </div>
          ) : (
            <div className="grid justify-center gap-5 mx-auto max-w-7xl py-3 sm:grid-cols-1 sm:px-8 2xl:grid-cols-5 lg:grid-cols-4 lg:px-5">
              {data["eventos"].map((evento) => {
                return <CardsEventos data={evento} />;
              })}
            </div>
          )}
          <div className="flex justify-center m-5">
            <Paginacao
              totalEventos={data["totalEventos"]}
              fetchData={fetchData}
            />
          </div>
        </main>
      </div>
    </>
  );
}
