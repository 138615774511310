import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Colaboradores from "./screens/Colaboradores";
import Cabecalho from "./global/Cabecalho";
import Fantasias from "./screens/Fantasias";
import EventosFinalizados from "./screens/EventosFinalizados";
import ViewEvento from "./screens/Home/view/ViewEvento";
import Login from "./screens/Login";
import GuardedRoute from "./GuardedRoute";
import EventosPendentes from "./screens/EventosPendentes";

function PrivateRoute({ element }) {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" />;
  }
  return element;
}

function AppRoutes() {
  return (
    <BrowserRouter>
      <Cabecalho />
      <Routes>
        <Route path="/login" element={<Login />} />

        {/* Rotas protegidas */}
        <Route path="/" element={<GuardedRoute element={<Home />} />} />
        <Route
          path="/colaboradores"
          element={<GuardedRoute element={<Colaboradores />} />}
        />
        <Route
          path="/fantasias"
          element={<GuardedRoute element={<Fantasias />} />}
        />
        <Route
          path="/eventosFinalizados"
          element={<GuardedRoute element={<EventosFinalizados />} />}
        />
        <Route
          path="/eventos/:id"
          element={<GuardedRoute element={<ViewEvento />} />}
        />
        <Route
          path="/eventosPendentes"
          element={<GuardedRoute element={<EventosPendentes />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
