import axios from "axios";
import urlApi from "../../urlApi";

const DeleteColaborador = async (id) => {
    const url = `${urlApi}/deleteColaboradores/${id}`;
    try{
        const response = await axios.put(url);
        if(response.status === 200){
            return true;
        }else{
            return false;
        }
    }catch(error){
        console.error(error)
        return false;
    }
  
}

export default DeleteColaborador;