import React from 'react'
import { TableColaboradores } from './components/TableColaboradores';
  
export default function Colaboradores() {
  return (
    <>
        <main>
            <div className='flex w-100 m-8 justify-center'>
              <TableColaboradores/>
            </div>
        </main>
    </>
    
    )
}
