import { PlusIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { StepperEventos } from "./SteperEventos";
import Step1 from "./StepsCreate/Step1";
import EventoContext from "../../../context/EventoContext";
import Step2 from "./StepsCreate/Step2";
import Step3 from "./StepsCreate/Step3";
import axios from "axios";
import Step4 from "./StepsCreate/Step4";
import { format } from "date-fns";
import urlApi from "../../../urlApi";

export default function DialogEditEventos({ id }) {
  const [open, setOpen] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [image, setImage] = useState();
  const [tituloEvento, setTituloEvento] = useState();
  const [nomeContratante, setNomeContratante] = useState();
  const [nomeAniversariante, setNomeAniversariante] = useState("");
  const [descricao, setDescricao] = useState("");
  const [idadeAniversariante, setIdadeAniversariante] = useState("");
  const [horarioDoBolo, setHorarioDoBolo] = useState("");
  const [endereco, setEndereco] = useState();
  const [telefoneContato, setTelefoneContato] = useState();
  const [horarioElenco, setHorarioElenco] = useState();
  const [isLastStep, setIsLastStep] = useState(true);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [horarioFinal, setHorarioFinal] = useState();
  const [selectedFantasia, setSelectedFantasia] = useState([]);
  const [itemsFantasia, setItemsFantasia] = useState([]);
  const [selectedColab, setSelectedColab] = useState([]);
  const [colabData, setColabData] = useState([]);
  const [itemsColab, setItemsColab] = useState([]);
  const isEdit = true;
  const idEvento = id;
  const handleOpen = () => setOpen(!open);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleImage = (imagem) => {
    setImage(imagem);
    console.log(imagem)
  };
  const handleTituloEventoChange = (event) => {
    setTituloEvento(event.target.value);
  };

  const handleNomeContratanteChange = (event) => {
    setNomeContratante(event.target.value);
  };

  const handleEnderecoChange = (event) => {
    setEndereco(event.target.value);
  };

  const handleTelefoneContatoChange = (event) => {
    setTelefoneContato(event.target.value);
  };

  const handleHorarioElencoChange = (event) => {
    setHorarioElenco(event.target.value);
  };
  const handleHorarioFinalChange = (event) => {
    setHorarioFinal(event.target.value);
  };
  const handleNomeAniversarianteChange = (event) => {
    setNomeAniversariante(event.target.value);
  };

  const handleDescricaoChange = (event) => {
    setDescricao(event.target.value);
  };

  const handleIdadeAniversarianteChange = (event) => {
    setIdadeAniversariante(event.target.value);
  };

  const handleHorarioDoBoloChange = (event) => {
    setHorarioDoBolo(event.target.value);
  };

  useEffect(() => {
    if (activeStep == 0) {
      if (
        tituloEvento &&
        nomeContratante &&
        endereco &&
        telefoneContato &&
        horarioElenco &&
        horarioFinal
      ) {
        setIsLastStep(false);
      } else {
        setIsLastStep(true);
      }
    }
    if (activeStep === 1) {
      setIsLastStep(false);
    }

    // if (activeStep === 2) {
    //   if (selectedFantasia.length > 0) {
    //     setIsLastStep(false);
    //   } else {
    //     setIsLastStep(true);
    //   }
    // }
    // if (activeStep === 3) {
    //   if (selectedColab.length > 0) {
    //     setIsLastStep(false);
    //   } else {
    //     setIsLastStep(true);
    //   }
    // }

  }, [
    image,
    tituloEvento,
    nomeContratante,
    endereco,
    telefoneContato,
    horarioElenco,
    activeStep,
    selectedColab,
    selectedFantasia
  ]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        `${urlApi}/allFantasias`
      );
      const responseColab = await axios.get(
        `${urlApi}/allColaboradores`
      );
      
      const responseAllInfo = await axios.get(
        `${urlApi}/oneEvento/${id}`
      )
      const responseFantasiaColab = await axios.get(
        `${urlApi}/atorFantasia/${id}`
      )
      const colabData = responseColab.data;
      const fantasiasData = response.data;

      const dataFC= responseFantasiaColab.data;
      const data = responseAllInfo.data;
      handleDateChange(new Date(data.data_evento_sf));
      setTituloEvento(data.nome_evento);
      setNomeContratante(data.nome_contratante);
      setEndereco(data.endereco);
      setTelefoneContato(data.telefone);
      setHorarioFinal(data.horario_final);
      setHorarioElenco(data.horario_elenco);
      setHorarioDoBolo(data.horario_bolo);
      setNomeAniversariante(data.nome_aniversariante);
      setIdadeAniversariante(data.idade_aniversariante);
      setDescricao(data.descricao);
      setSelectedFantasia(dataFC.fantasias);
      setSelectedColab(dataFC.atores);

      const colaboradoresFiltrados = colabData.filter(obj => !dataFC["atores"].some(item => item.id === obj.id));
      const fantasiasFiltradas = fantasiasData.filter(obj => !dataFC["fantasias"].some(item => item.id === obj.id));

      setItemsColab(colaboradoresFiltrados);
      setItemsFantasia(fantasiasFiltradas);
    }
    fetchData();
    return () => {
      setSelectedFantasia([]);
      setSelectedColab([]);
    };
  }, [])

  return (
    <EventoContext.Provider
      value={{
        handleDateChange,
        handleImage,
        handleTituloEventoChange,
        handleNomeContratanteChange,
        handleEnderecoChange,
        handleTelefoneContatoChange,
        handleHorarioFinalChange,
        handleHorarioElencoChange,
        handleNomeAniversarianteChange,
        handleIdadeAniversarianteChange,
        handleHorarioDoBoloChange,
        handleDescricaoChange,
        setIsLastStep,
        setIsFirstStep,
        setUploadedImage,
        setActiveStep,
        setOpen,
        setSelectedFantasia,
        setItemsFantasia,
        setSelectedColab,
        setItemsColab,
        setColabData,
        colabData,
        itemsColab,
        selectedColab,
        itemsFantasia,
        selectedFantasia,
        idadeAniversariante,
        horarioDoBolo,
        descricao,
        nomeAniversariante,
        horarioFinal,
        activeStep,
        selectedDate,
        uploadedImage,
        isLastStep,
        isFirstStep,
        tituloEvento,
        nomeContratante,
        endereco,
        image,
        telefoneContato,
        horarioElenco,
        isEdit,
        idEvento
      }}
    >
      <Button
        onClick={handleOpen}
        color="orange"
      >
        {" "}
        Editar evento{" "}
      </Button>
      {open && (
        <Dialog
          className="bg-[#1e1e1e] "
          open={open}
          size={"lg"}
          handler={handleOpen}
        >
          <DialogHeader className="text-white opacity-80 overflow-auto">
            Editar evento
          </DialogHeader>
          <DialogBody
            divider
            className=" flex flex-col gap-5 max-h-[400px] overflow-y-auto"
          >
            {activeStep === 0 ? <Step1 id={id}/> : null}
            {activeStep === 1 ? <Step2 /> : null}
            {activeStep === 2 ? <Step3 isEdit={true}/> : null}
            {activeStep === 3 ? <Step4 /> : null}

          </DialogBody>
          <DialogFooter>
            <StepperEventos id={id} isEdit={true}/>
          </DialogFooter>
        </Dialog>
      )}
    </EventoContext.Provider>
  );
}
