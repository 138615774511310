import { Input, Typography } from "@material-tailwind/react";
import React from "react";
import ReactInputMask from "react-input-mask";
import ButtonForImage from "../ButtonForImage";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import "../../DatePicker.css";
import useEventoContext from "../../../../hook/useEventoContext";

export default function Step1({ id }) {
  const {
    handleDateChange,
    handleImage,
    handleHorarioFinalChange,
    selectedDate,
    handleTituloEventoChange,
    handleNomeContratanteChange,
    handleEnderecoChange,
    handleTelefoneContatoChange,
    handleHorarioElencoChange,
    horarioFinal,
    tituloEvento,
    nomeContratante,
    endereco,
    telefoneContato,
    horarioElenco,
  } = useEventoContext();

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-5">
        <div className="w-full">
          <Input
            required
            onChange={handleTituloEventoChange}
            value={tituloEvento}
            label="Título do evento"
            className="text-white"
            color="orange"
          />
        </div>
        <div className="w-full">
          <Input
            required
            onChange={handleNomeContratanteChange}
            value={nomeContratante}
            label="Nome do contratante"
            className="text-white"
            color="orange"
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-5 justify-between">
        <div className="w-full lg:w-64 ">
          <Input
            required
            onChange={handleEnderecoChange}
            value={endereco}
            label="Endereço"
            className="text-white"
            color="orange"
          />
        </div>

        <div className="flex gap-5">
          <div className="w-1/2 md:w-72 lg:w-48">
            <ReactInputMask
              required
              onChange={handleTelefoneContatoChange}
              value={telefoneContato}
              className="peer h-full w-full rounded-[7px] placeholder:text-blue-gray-500 border border-blue-gray-200  bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              mask="(99) 99999-9999"
              placeholder="Telefone de contato"
            />
          </div>

          <div className="flex justify-center gap-2 items-center ">
            <Typography color="white">Data:</Typography>
            <div className="text-center">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                locale={ptBR}
                className="rounded-[7px] w-28 placeholder:text-blue-gray-500 border border-blue-gray-200  bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              />
            </div>
          </div>
        </div>
        <section className="flex gap-2 items-center">
          <Typography color="white">Horário do elenco:</Typography>
          <div className="w-full lg:w-16">
            <ReactInputMask
              required
              value={horarioElenco}
              onChange={handleHorarioElencoChange}
              placeholder="inicial"
              className="peer h-full w-full rounded-[7px] placeholder:text-blue-gray-500 border border-blue-gray-200  bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              mask="99:99"
            />
          </div>
          <div className="w-full lg:w-16">
            <ReactInputMask
              required
              value={horarioFinal}
              onChange={handleHorarioFinalChange}
              placeholder="final"
              className="peer h-full w-full rounded-[7px] placeholder:text-blue-gray-500 border border-blue-gray-200  bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              mask="99:99"
            />
          </div>
        </section>
      </div>
    
      <div className="flex">
        <ButtonForImage onImage={handleImage} id={id} isEdit={true} />
      </div>
    </>
  );
}
