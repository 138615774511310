import axios from "axios";
import urlApi from "../../urlApi";

async function  EncerrarEvento (idEvento){
  const url = `${urlApi}/encerrarEvento/${idEvento}`;
  axios
    .put(url)
    .then((response) => {
        if(response.status === 200){
            return true;
        }
        return false;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

export default EncerrarEvento;
