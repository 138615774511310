import axios from "axios"
import urlApi from "../../urlApi";

const DeleteEvento =  async (idEvento) => {
    const url = `${urlApi}/deleteEvento/${idEvento}`;
    axios.put(url).then(response => {
        if(response.status === 200){
            return true
        }
        return false
    }).catch(error => {
        console.log(error)
        return false
    })
}

export default DeleteEvento;