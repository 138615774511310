import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { TrashIcon, UserIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { DialogColaborador } from "./DialogCreateColaborador";
import { DialogEditColaborador } from "./DialogEditColaborador";
import DeleteColaborador from "../../../controller/colaboradores/DeleteColaborador";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import urlApi from "../../../urlApi";
import { TabsColaboradoresAtivos } from "./TabsColab";
import AtivarColaborador from "../../../controller/colaboradores/AtivarColaborador";

const TABLE_HEAD = [
  "Membros de Elenco",
  "Etnia/altura",
  "Vezes Trabalhadas",
  "Telefone",
  "Idade",
  "",
  "",
];

export function TableColaboradores() {
  const [data, setData] = useState([]);
  const [paginacao, setPaginacao] = useState(1);
  const [habilitarNext, setHabilitarNext] = useState(false);
  const [habilitarPrevious, setHabilitarPrevious] = useState(true);
  const [activeTab, setActiveTab] = React.useState("ativos");

  const navigate = useNavigate();

  const fetchData = async (pagina) => {
    try {
      const response = await axios.get(`${urlApi}/colaboradores/${pagina}`);
      if (data === 0 && pagina > 1) {
        setHabilitarNext(true);
      } else {
        setHabilitarNext(false);
      }
      if (pagina <= 1) {
        setHabilitarPrevious(true);
      } else {
        setHabilitarPrevious(false);
      }
      setData(response.data);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      background: "#1e1e1e",
      color: "#fff",
      title: "Você tem certeza?",
      text: "Mas fique tranquilo, caso mude de ideia, poderá reverter isso depois!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006400",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim, deletar!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (DeleteColaborador(id)) {
          Swal.fire({
            background: "#1e1e1e",
            color: "#fff",
            title: "Deletado!",
            html: "Seu membro de elenco foi deletado com sucesso.",
            icon: "success",
          });
        } else {
          Swal.fire({
            background: "#1e1e1e",
            color: "#fff",
            title: "Algo deu errado!",
            html: "Tente novamente mais tarde.",
            icon: "error",
          });
        }
      }
    });
    // fetchData(1);
  };

  const ativarMembro = async (id) => {
    Swal.fire({
      background: "#1e1e1e",
      color: "#fff",
      title: "Você tem certeza?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006400",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim, Ativar!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (AtivarColaborador(id)) {
          Swal.fire({
            background: "#1e1e1e",
            color: "#fff",
            title: "Ativado!",
            html: "Seu membro de elenco foi Ativado com sucesso.",
            icon: "success",
          });
        } else {
          Swal.fire({
            background: "#1e1e1e",
            color: "#fff",
            title: "Algo deu errado!",
            html: "Tente novamente mais tarde.",
            icon: "error",
          });
        }
      }
    });
  };

  const searchData = async (data) => {
    try {
      if (activeTab === "ativos") {
        const response = await axios.get(
          `${urlApi}/searchColaboradores/${data}`
        );
        setData(response.data);
      } else {
        const response = await axios.get(`${urlApi}/searchDesativos/${data}`);
        setData(response.data);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  return (
    <Card className="h-full w-full bg-[#404040]">
      <CardHeader
        floated={false}
        shadow={false}
        className="rounded-none bg-[#404040]"
      >
        <div className="mb-8 flex items-center justify-between gap-8 bg-[#404040]">
          <div>
            <Typography variant="h5" color="white">
              Membros de elenco
            </Typography>
            <Typography color="white" className="mt-1 font-normal">
              Veja todas as informações dos seus membros de elenco
            </Typography>
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row ">
            <DialogColaborador />
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row bg-[#404040]">
          <div className="w-full md:w-72 ">
            <Input
              onChange={(data) => searchData(data.target.value)}
              label="Pesquisar"
              color="orange"
              className="text-white"
              icon={<MagnifyingGlassIcon className="h-5 w-5" color="white" />}
            />
          </div>
          <TabsColaboradoresAtivos
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setData={setData}
          />
        </div>
      </CardHeader>
      <CardBody className="overflow-auto sm:overflow-scroll lg:overflow-y-hidden lg:overflow-x-hidden px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-[#404040] p-4"
                >
                  <Typography
                    variant="small"
                    color="white"
                    className="font-normal leading-none "
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(
              (
                {
                  id,
                  nome,
                  email,
                  altura,
                  qnt_trabalhados,
                  telefone,
                  etnia,
                  idade,
                },
                index
              ) => {
                const isLast = index === data.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Avatar
                          src={`${urlApi}/media/colaboradores/${id}-0.jpg`}
                          alt={nome}
                          size="md"
                        />
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="white"
                            className="font-normal"
                          >
                            {nome}
                          </Typography>
                          <Typography
                            variant="small"
                            color="white"
                            className="font-normal opacity-70"
                          >
                            {email}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="white"
                          className="font-normal"
                        >
                          {etnia}
                        </Typography>
                        <Typography
                          variant="small"
                          color="white"
                          className="font-normal opacity-70"
                        >
                          {altura}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Typography
                          variant="small"
                          color="white"
                          className="font-normal "
                        >
                          {qnt_trabalhados}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="white"
                        className="font-normal"
                      >
                        {telefone}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="white"
                        className="font-normal"
                      >
                        {idade}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Tooltip content="Editar membro de elenco">
                        <DialogEditColaborador id={id} />
                      </Tooltip>
                    </td>
                    {activeTab === "ativos" ? (
                      <td className={classes}>
                        <Tooltip content="Deletar membro de elenco">
                          <IconButton
                            onClick={() => handleDelete(id)}
                            variant="text"
                            color="red"
                          >
                            <TrashIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    ) : (
                      <td className={classes}>
                        <Tooltip content="Ativar membro de elenco">
                          <IconButton
                            onClick={() => ativarMembro(id)}
                            variant="text"
                            color="green"
                          >
                            <UserIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-end border-t border-blue-gray-50 p-4">
        <div className="flex gap-2">
          <Button
            disabled={habilitarPrevious}
            variant="outlined"
            color="white"
            size="sm"
            onClick={async () => {
              setPaginacao(paginacao - 1);
              fetchData(paginacao - 1);
            }}
          >
            Anterior
          </Button>
          <Button
            disabled={habilitarNext}
            variant="outlined"
            color="white"
            size="sm"
            onClick={() => {
              setPaginacao(paginacao + 1);
              fetchData(paginacao + 1);
            }}
          >
            Próximo
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
