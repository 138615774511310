import React, { useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Typography,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export default function MenuFuncao({ id, handler, funcao, removerFantasia }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [tituloMenu, setTituloMenu] = useState(funcao);
  const funcoes = [{ funcao: "Ator" }, { funcao: "Coordenador" }];

  return (
    <Menu open={openMenu} handler={setOpenMenu} allowHover>
      <MenuHandler>
        <Button
          color="orange"
          fullWidth
          variant="text"
          className="flex gap-3 text-start rounded-br-[20px] justify-between"
        >
          {tituloMenu}
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3.5 w-3.5 transition-transform ${
              openMenu ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="hidden   gap-3 overflow-visible lg:grid z-[9999] bg-gray-900 border-gray-800">
        <ul className="flex w-full flex-col gap-1">
          {funcoes.map(({ funcao }) => {
            return (
              <MenuItem
                key={funcao}
                onClick={() => {
                  if(funcao=== "Coordenador"){
                    removerFantasia(id)
                  }

                  setTituloMenu(funcao);
                  handler(funcao);
                }}
                className="hover:bg-gray-800 flex gap-3"
              >
                <Typography variant="h6" color="white" className="mb-1">
                  {funcao}
                </Typography>
              </MenuItem>
            );
          })}
        </ul>
      </MenuList>
    </Menu>
  );
}
