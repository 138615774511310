import React from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "@material-tailwind/react";
import useEventoContext from "../../../hook/useEventoContext";
import urlApi from "../../../urlApi";

export default function ButtonForImage({ onImage, image, id, isEdit }) {
  const { setUploadedImage, uploadedImage } = useEventoContext();

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedImage(reader.result);
      onImage(reader.result.split(",")[1]);
    };

    reader.readAsDataURL(file);
  };
  let imageDefault; 
  
  if(isEdit){
    imageDefault = image === undefined
    ? `${urlApi}/media/eventos/${id}.jpg`
    : `${urlApi}/media/fantasias/${image}`;
  }else{
    imageDefault = image === undefined
    ? `${urlApi}/media/fantasias/imagemDefault.png`
    : `${urlApi}/media/fantasias/${image}`;

  }
 
  

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        {...getRootProps()}
        className={`dropzone ${
          isDragActive ? "active" : ""
        } flex gap-5 items-center justify-center`}
      >
        <div className="flex justify-center cursor-pointer">
          <img
            className="h-[300px] w-full lg:w-[150px] lg:h-[150px] rounded-lg object-cover object-center"
            src={uploadedImage != null ? uploadedImage : imageDefault}
            alt="Imagem"
          />
        </div>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Button
            color="orange"
            variant="gradient"
            className="flex items-center h-10 gap-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              />
            </svg>
            Arraste a imagem aqui
          </Button>
        ) : (
          <Button
            color="orange"
            variant="gradient"
            className="flex items-center h-10  gap-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              />
            </svg>
            Selecionar Imagem
          </Button>
        )}
      </div>
    </div>
  );
}
