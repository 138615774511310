import axios from "axios";
import urlApi from "../../urlApi";

const UpdateColaborador = (
    id,
    nome, 
    email, 
    senha, 
    telefone, 
    endereco, 
    altura, 
    cpf, 
    descricao,
    etnia, 
    tatuagem, 
    piercing, 
    barba, 
    numeroCalcado,
    salto,
    aparelho,
    qntTrabalhados,
    numeracaoRoupa,
    dadosBancarios,
    nivelAcesso,
    dataNascimento,
    base64
    ) => {
    const url = `${urlApi}/updateColaboradores/${id}`;
    
    const data = {
        "nome": nome,
        "email": email,
        "senha": senha,
        "telefone": telefone,
        "endereco": endereco,
        "altura": altura,
        "cpf": cpf,
        "descricao": descricao,
        "etnia": etnia,
        "tatuagem": tatuagem,
        "piercing": piercing,
        "barba": barba,
        "numero_calcado": numeroCalcado,
        "salto": salto,
        "aparelho": aparelho,
        "qnt_trabalhados": qntTrabalhados,
        "numeracao_roupa": numeracaoRoupa,
        "dados_bancarios": dadosBancarios,
        "nivel_acesso": nivelAcesso,
        "data_exclusao": null,
        "data_nasci": dataNascimento,
        "base64Image": base64
    };
    axios.put(url, data)
    .then(response => {
        console.log('Resposta:', response.data);
    })
    .catch(error => {
        console.error('Erro:', error);
    });
}

export default UpdateColaborador;