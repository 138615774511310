import React , {useState}from 'react'
import { useDropzone } from 'react-dropzone';
import {
    Button,
    Avatar,
} from "@material-tailwind/react";
import urlApi from '../../../urlApi';

function ButtonforImage({setImage, isEdit, idColaborador, idImage}) {
    const [uploadedImage, setUploadedImage] = useState(null);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            setUploadedImage(reader.result);
            setImage(reader.result.split(",")[1]);
        };

        reader.readAsDataURL(file);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


    return (
        <div className="w-full hidden lg:block ">
            <div className="flex justify-center">
                <Avatar src={uploadedImage != null ? uploadedImage : isEdit ?`${urlApi}/media/colaboradores/${idColaborador}-${idImage}.jpg` :`${urlApi}/media/colaboradores/imageDefault.png`} alt="avatar" className="w-[160px] h-[160px]" />
            </div>

            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} mt-7 flex justify-center`}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <Button color="orange" variant="gradient" className="flex items-center gap-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                            />
                        </svg>
                        Arraste a imagem aqui
                    </Button>
                ) : (
                    <Button color="orange" variant="gradient" className="flex items-center gap-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                            />
                        </svg>
                        Selecionar Imagem
                    </Button>
                )}
            </div>
        </div>
    )
}

export default ButtonforImage