import React from "react";
import { Tabs, TabsHeader, Tab } from "@material-tailwind/react";
import axios from "axios";
import urlApi from "../../../urlApi";

export function TabsColaboradoresAtivos({ activeTab, setActiveTab, setData }) {
  const fetchDataAtivo = async () => {
    try {
      const response = await axios.get(`${urlApi}/colaboradores/${1}`);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataDesativos = async () => {
    try {
      const response = await axios.get(`${urlApi}/colaboradoresDesativos/`);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const data = [
    {
      label: "Ativos",
      value: "ativos",
      onClick: fetchDataAtivo,
    },
    {
      label: "Desativos",
      value: "desativos",
      onClick: fetchDataDesativos,
    },
  ];
  return (
    <Tabs value={activeTab}>
      <TabsHeader
        indicatorProps={{
          className: "bg-orange-400 !text-white",
        }}
      >
        {data.map(({ label, value, onClick }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => {
              setActiveTab(value);
              onClick();
            }}
          >
            <div
              className={`flex items-center gap-2 ${
                activeTab === value ? "text-blue-gray-50" : ""
              }`}
            >
              {label}
            </div>
          </Tab>
        ))}
      </TabsHeader>
    </Tabs>
  );
}
