import {
  Avatar,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import CardFantasia from "./CardFantasia";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useDropzone } from "react-dropzone";
import urlApi from "../../../../urlApi";

export default function AvatarColaboradores({ id, idEvento, roteiro }) {
  const [open, setOpen] = useState();
  const [dataUser, setDataUser] = useState();
  const [fantasia, setFantasia] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [sizeFile, setSizeFile] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("idColaborador", id);
    formData.append("idEvento", idEvento)
    // Do something with the files
    setUploadedFile(acceptedFiles[0]);
    const size = acceptedFiles[0].size;
    if (size < 1000000) {
      setSizeFile(`${Math.round(size / 1000)}kb`);
    } else if (size >= 1000000) {
      setSizeFile(`${size / 1000000}mb`);
    }

    async function postData() {
      const response = await axios.post(
        `${urlApi}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    postData();
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleOpen = () => setOpen(!open);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        `${urlApi}/colaborador/${id}`
      );
      const responseFantasia = await axios.get(
        `${urlApi}/fantasiaAtor/${id}/${idEvento}`
      );
      setDataUser(response.data);
      setFantasia(responseFantasia.data);
    }
    fetchData();
  }, []);

  return (
    <>
      <Avatar
        onClick={handleOpen}
        size="xl"
        className="cursor-pointer	"
        src={`${urlApi}/media/colaboradores/${id}-0.jpg`}
      />
      {open && (
        <Dialog
          open={open}
          size={"xs"}
          className="bg-[#1e1e1e] "
          handler={handleOpen}
        >
          <DialogHeader className="flex-col items-start">
            <div className="flex justify-between w-full">
              <Typography variant="h5" color="white">
                {dataUser && dataUser.nome}
              </Typography>
              <IconButton
                color="white"
                size="sm"
                variant="text"
                onClick={handleOpen}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </div>
            <Typography
              variant="small"
              className="font-semibold opacity-70 text-gray-200"
            >
              Telefone: {dataUser && dataUser.telefone}
            </Typography>
            <Typography
              variant="small"
              className="font-semibold opacity-70 text-gray-200"
            >
              Altura: {dataUser &&  dataUser.altura}
            </Typography>
          </DialogHeader>{" "}
          <DialogBody>
            <div className="mb-6">
              {fantasia === null ? (
                <>
                  <Typography
                    variant="small"
                    className="font-semibold opacity-70 text-gray-200"
                  >
                    Função
                  </Typography>
                  <Typography
                    variant="h5"
                    className="font-semibold opacity-70 text-white"
                  >
                    Coordenador
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="small"
                    className="font-semibold opacity-70 text-gray-200"
                  >
                    Personagem
                  </Typography>
                  <CardFantasia
                    key={fantasia.id}
                    content={fantasia.nome}
                    descricao={fantasia.descricao}
                    imagem={fantasia.imagens.split(",")[1]}
                  />
                </>
              )}
            </div>

            <div>
              <Typography
                variant="small"
                className="font-semibold opacity-70 text-gray-200"
              >
                Roteiro
              </Typography>
              <div className="px-4 py-6 w-full sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <input {...getInputProps()} />
                <dd className="mt-2 w-full lg:w-[150%] text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul
                    role="list"
                    className="divide-y divide-gray-100 rounded-md border border-gray-200"
                  >
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        <PaperClipIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium text-white">
                            {roteiro === null ? uploadedFile && uploadedFile.name : roteiro}
                          </span>

                          <span className="flex-shrink-0 text-gray-400">
                            {sizeFile}
                          </span>
                        </div>
                      </div>
                      <div className="ml-4 flex-shrink-0" {...getRootProps()}>
                        <a
                          href="#"
                          className="font-medium text-orange-600 hover:text-indigo-500"
                        >
                          {uploadedFile != null ? (
                            isDragActive ? (
                              <p>Arraste o arquivo aqui ...</p>
                            ) : (
                              <p>Enviar outro</p>
                            )
                          ) : isDragActive ? (
                            <p>Arraste o arquivo aqui...</p>
                          ) : (
                            <p>Enviar arquivo</p>
                          )}
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="gradient"
              color="green"
              onClick={() => handleOpen(null)}
            >
              <span>Ok</span>
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
}
