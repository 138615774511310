import { CheckIcon } from "@heroicons/react/20/solid";
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Badge,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import urlApi from "../../../urlApi";

export function CardsEventos({ data }) {
  return (
    <Badge
    placement="bottom-end"
    content={
      data.pago ?
      <CheckIcon className="h-5 w-5 text-white" strokeWidth={2.5} />
      : <XMarkIcon className="h-5 w-5 text-white" strokeWidth={2.5} />
    }
    className={`bg-gradient-to-tr ${data.pago ? "from-green-400 to-green-600" : "from-red-400 to-red-600"}  border-2 border-white shadow-lg shadow-black/20`}
  >


    <Card className="mt-5 w-96 sm:w-11 lg:w-72 bg-[#2d2d2d] ">
      <CardHeader color="blue-gray" className="relative h-56">
          <img
            className="bg:cover"
            src={`${urlApi}/media/eventos/${data.id}.jpg`}
            alt="Imagem"
          />
      </CardHeader>
      <CardBody>
        <Typography variant="h5" color="white" className="mb-2">
          {data.nome_evento}
        </Typography>
        <Typography color="white" className="truncate">
          {data.descricao}
        </Typography>
        <Typography color="white">
          Data do evento: {data.data_evento}
        </Typography>
        <Typography color="white">
          Horário do elenco: {data.horario_elenco}
        </Typography>
      </CardBody>
      <CardFooter className="pt-0">
        <Link to={`/eventos/${data.id}`}>
          <Button className="bg-[#ddb900] hover:shadow-[#ddb900] shadow-orange-300">
            Saber mais!
          </Button>
        </Link>
      </CardFooter>
    </Card>
  </Badge>
  );
}
