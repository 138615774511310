import { CardsEventos } from "../Home/components/CardsEventos";
import { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "@material-tailwind/react";
import urlApi from "../../urlApi";

export default function EventosPendentes() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await axios.get(`${urlApi}/eventosPendentes`);
          setData(response.data);
          response.status === 200 ? setLoading(false) : setLoading(true)
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }
      };
    fetchData();
  }, []);

  return (
    <>
      <div className="min-h-full">
        
        <main>
          {
            loading ? 
            <div className="flex justify-center">
              <Spinner color="amber"  className="h-12 w-12 "/> 
            </div> :
            <div className="grid justify-center gap-5 mx-auto max-w-7xl py-3 sm:grid-cols-1 sm:px-8 2xl:grid-cols-4 lg:grid-cols-4 lg:px-5">
                {data["eventos"].map(evento => {
                  return <CardsEventos data={evento}/>
                })}
            </div>
          }
        </main>
      </div>
    </>
  )
}
