import React, { useState } from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Typography,
  Avatar,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import useEventoContext from "../../../../../hook/useEventoContext";
import urlApi from "../../../../../urlApi";

export default function MenuFantasia({ id, fantasia }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [tituloMenu, setTituloMenu] = useState(fantasia);
  const { selectedFantasia, selectedColab, setSelectedColab } =
    useEventoContext();

  const addOrEditColab = (idFantasia) => {
    // Verifica se o idFantasia já existe na lista
    const existingColab = selectedColab.find((colab) => colab.id === id);

    if (existingColab) {
      // Se existir, atualize o idFantasia
      setSelectedColab((prevData) => {
        return prevData.map((colab) => {
          if (colab.id === id) {
            return { ...colab, idFantasia: idFantasia };
          } else {
            return { ...colab };
          }
        });
      });
    } else {
      // Se não existir, adicione um novo objeto à lista
      setSelectedColab((prevData) => [
        ...prevData,
        { id: id, idFantasia: idFantasia },
      ]);
    }
  };

  return (
    <Menu open={openMenu} handler={setOpenMenu} allowHover>
      <MenuHandler>
        <Button
          color="orange"
          fullWidth
          variant="text"
          className="flex gap-3 text-start rounded-bl-[20px] justify-between"
        >
          {tituloMenu}
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3.5 w-3.5 transition-transform ${
              openMenu ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="hidden w-[36rem]  gap-3 overflow-visible lg:grid z-[9999] bg-gray-900 border-gray-800">
        <ul className="flex w-full flex-col gap-1">
          {selectedFantasia.map(({ id, nome, descricao, imagens }) => {
            return (
              <MenuItem
                key={nome}
                onClick={() => {
                 
                  setTituloMenu(nome);
                  addOrEditColab(id);
                }}
                className="hover:bg-gray-800 flex gap-3"
              >
                <div>
                  <Avatar
                    src={`${urlApi}/media/fantasias/${imagens[0]}`}
                    alt="avatar"
                    className="z-40"
                  />
                </div>
                <div>
                  <Typography variant="h6" color="white" className="mb-1">
                    {nome}
                  </Typography>
                  <Typography
                    variant="small"
                    className="font-normal text-gray-400"
                  >
                    {descricao}
                  </Typography>
                </div>
              </MenuItem>
            );
          })}
        </ul>
      </MenuList>
    </Menu>
  );
}
