import React from "react";
import { Button, IconButton, Typography } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export function Paginacao({ totalEventos, fetchData }) {
  const [active, setActive] = React.useState(1);
  const totalPag = Math.ceil(totalEventos/8);
  const paginas = []

  
  const getItemProps = (index) =>
  ({
    variant: active === index ? "filled" : "text",
    color: active === index ? "orange" : "blue-gray",
    onClick: () => {
      setActive(index)
      fetchData(index)
    },
  });
  for(let i = 1; i<=totalPag; i++){
    paginas.push(<IconButton {...getItemProps(i)}>{i}</IconButton>)
  }
  
  const next = () => {
    if (active === totalPag) return;
    setActive(active + 1);
    fetchData(active + 1)
  };
 
  const prev = () => {
    if (active === 1) return;
    setActive(active - 1);
    fetchData(active - 1)
  };
 
  return (
    <>
        <div className="hidden lg:flex items-center gap-4 ">
        <Button
            variant="text"
            color="orange"
            className="flex items-center gap-2"
            onClick={prev}
            disabled={active === 1}
        >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Anterior
        </Button>
        <div className="flex items-center gap-2">
            {paginas}
        </div>
        <Button
            variant="text"
            color="orange"
            className="flex items-center gap-2"
            onClick={next}
            disabled={active === totalPag}
        >
            Próximo
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
        </Button>
        </div>

        
        <div className="flex lg:hidden items-center gap-8">
        <IconButton
            size="sm"
            variant="outlined"
            color="orange"
            onClick={prev}

            disabled={active === 1}
        >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
        </IconButton>
        <Typography color="gray" className="font-normal">
            Página <strong className="text-orange-500">{active}</strong>
        </Typography>
        <IconButton
            size="sm"
            variant="outlined"
            color="orange"
            onClick={next}
            disabled={active === totalPag}
        >
            <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
        </IconButton>
        </div>
    </>
    
  );
}