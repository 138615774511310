import React from 'react';
import { Navigate } from 'react-router-dom';

function GuardedRoute({ element }) {
  const token = localStorage.getItem('token');

  if (!token) {
    // Redireciona para a página de login se o token não estiver presente
    return <Navigate to="/login" />;
  }

  // Permite o acesso à rota desejada se o token estiver presente
  return element;
}
export default GuardedRoute