import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import urlApi from "../../urlApi";

function CreateEvento(
  selectedColab,
  selectedFantasia,
  idadeAniversariante,
  horarioDoBolo,
  descricao,
  nomeAniversariante,
  horarioFinal,
  selectedDate,
  image,
  tituloEvento,
  nomeContratante,
  endereco,
  telefoneContato,
  horarioElenco,
  setOpen,
  pagamento
) {
  const url = `${urlApi}/createEventos`;

  const data = {
    endereco: endereco,
    horario: null,
    nome_evento: tituloEvento,
    nome_contratante: nomeContratante,
    nome_aniversariante: nomeAniversariante,
    idade_aniversariante: idadeAniversariante,
    telefone: telefoneContato,
    horario_final: horarioFinal,
    horario_bolo: horarioDoBolo,
    horario_elenco: horarioElenco,
    data_evento: selectedDate,
    data_exclusao: null,
    descricao: descricao,
    base64Image: image,
    colaboradores: selectedColab,
    fantasias: selectedFantasia,
    preco: pagamento,
    pago: false
  };
  axios
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        setOpen(false);
        Swal.fire({
          title: "Bom trabalho!",
          color: "#fff",
          html: "Você criou um novo evento!",
          icon: "success",
          showConfirmButton: false,
          background: "#1e1e1e",
          timer: 1500,
          timerProgressBar: true,
        });

      }else{
        setOpen(false)
        Swal.fire({
            title: "Houve algum problema!",
            color: "#fff",
            html: `${response.data}`,
            icon: "error",
            showConfirmButton: false,
            background: "#1e1e1e",
            timer: 1500,
            timerProgressBar: true,
          });
      }
    })
    .catch((error) => {
      console.error("Erro:", error);
      setOpen(false)
      Swal.fire({
        title: "Houve algum problema!",
        color: "#fff",
        html: `${error}`,
        icon: "error",
        showConfirmButton: false,
        background: "#1e1e1e",
        timer: 1500,
        timerProgressBar: true,
      });
    });
}

export default CreateEvento;
