import React from 'react'
import { TableFantasias } from './components/TableFantasias'

export default function Fantasias() {
  return (
    <main>
        <div className='flex w-100 m-8 justify-center'>
            <TableFantasias/>
        </div>
    </main>
  )
}
