import React, { useState } from "react";
import {
  Button,
  Carousel,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Collapse,
  CardBody,
  Avatar,
  Card,
  Typography,
} from "@material-tailwind/react";
import urlApi from "../../../urlApi";

export default function DialogViewFantasia({
  id,
  imagem,
  nome,
  listImagens,
  listImagensMaquiagem,
  descricao,
  isEvento,
}) {
  const [open, setOpen] = useState(false);
  const [openColapse, setOpenColapse] = useState(false);
  const handleOpen = () => setOpen(!open);
  const listAllImages = [...listImagens, ...listImagensMaquiagem];

  return (
    <>
      {isEvento === true ? (
        <Avatar
          className="cursor-pointer	"
          onClick={handleOpen}
          size="xl"
          src={`${urlApi}/media/fantasias/${listAllImages[0]}`}
        />
      ) : (
        <img
          onClick={handleOpen}
          src={`${urlApi}/media/fantasias/${imagem}`}
          alt="avatar"
          className="w-[250px] h-[250px] transition-transform duration-500 hover:scale-110  border border-transparent rounded-2xl"
        />
      )}
      {open && (
        <Dialog
          className="bg-[#1e1e1e]"
          open={open}
          size={"md"}
          handler={handleOpen}
        >
          <DialogHeader className="text-white opacity-80 overflow-auto">
            <div>
              <div className="">{nome}</div>
              <Typography
                onClick={() => setOpenColapse(!openColapse)}
                variant={"lead"}
                className="cursor-pointer"
              >
                {openColapse === false
                  ? "Mostrar Descrição"
                  : "Ocultar Descrição"}
              </Typography>
              <Collapse open={openColapse}>
                <Card className="my-4 mx-auto w-full bg-[#404040]">
                  <CardBody>
                    <Typography color="white">{descricao}</Typography>
                  </CardBody>
                </Card>
              </Collapse>
            </div>
          </DialogHeader>
          <DialogBody
            divider
            className="flex justify-center max-h-[400px] overflow-y-auto"
          >
            <div>
              <Carousel
                className="rounded-xl  h-[365px]"
                navigation={({ setActiveIndex, activeIndex, length }) => (
                  <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                    {new Array(length).fill("").map((_, i) => (
                      <span
                        key={i}
                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                          activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                        }`}
                        onClick={() => setActiveIndex(i)}
                      />
                    ))}
                  </div>
                )}
              >
                {listAllImages.map((imagens) => {
                  return (
                    <img
                      src={`${urlApi}/media/fantasias/${imagens}`}
                      alt={imagens}
                      className="h-full w-full object-contain"
                    />
                  );
                })}
              </Carousel>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button variant="gradient" color="green" onClick={handleOpen}>
              <span>Ok</span>
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
}
