import axios from "axios";
import urlApi from "../../urlApi";

const DeleteFantasia = (id) => {
    const url = `${urlApi}/deleteFantasia/${id}`;
    axios.put(url)
    .then(response => {
        console.log('Resposta:', response.data);
    })
    .catch(error => {
        console.error('Erro:', error);
    });
}

export default DeleteFantasia;