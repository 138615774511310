import axios from 'axios';
import urlApi from '../../urlApi';

const EditFantasia = ( 
    id,
    nome, 
    descricao, 
    listImagesFantasias,
    listImagesMaquiagem,
    musicaTema,
    localDaPeca,
    videos,
    videoMaquiagem
    ) => {
    const url = `${urlApi}/updateFantasia/${id}`;

    const data = {
        "nome": nome,
        "descricao": descricao,
        "base64ImageFantasia": listImagesFantasias,
        "base64ImageMaquiagem": listImagesMaquiagem,
        "musica_tema": musicaTema,
        "videos": videos,
        "video_maquiagem": videoMaquiagem,
        "local": localDaPeca
    };
    axios.put(url, data)
    .then(response => {
        console.log('Resposta:', response.data);
    })
    .catch(error => {
        console.error('Erro:', error);
    });
}

export default EditFantasia;