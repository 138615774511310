import React, { useState } from "react";
import {
  UserPlusIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/solid";
import { useDropzone } from "react-dropzone";
import Datepicker from "tailwind-datepicker-react";
import {
  Button,
  Dialog,
  Input,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Select,
  Option,
  Checkbox,
  Textarea,
  Avatar,
} from "@material-tailwind/react";
import ReactInputMask from "react-input-mask";
import CreateColaboradores from "../../../controller/colaboradores/CreateColaboradores";
import ButtonforImage from "./ButtonforImage";
import urlApi from "../../../urlApi";

const options = {
  title: "Data de nascimento",
  autoHide: true,
  todayBtn: false,
  clearBtn: true,
  maxDate: new Date("2030-01-01"),
  minDate: new Date("1930-01-01"),
  theme: {
    background: "bg-gray-900 dark:bg-gray-800",
    todayBtn: "bg-orange-500 text-white hover:bg-orange-500",
    clearBtn: "bg-orange-500 text-white hover:bg-orange-500",
    icons: "bg-transparent text-white hover:bg-orange-500",
    text: "text-white hover:bg-orange-500",
    disabledText: "bg-orange-500 text-white",
    input: "bg-transparent text-white border-blue-gray-400",
    inputIcon: "hover:bg-orange-500",
    selected: "",
  },
  icons: {
    // () => ReactElement | JSX.Element
    prev: () => <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />,
    next: () => <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />,
  },
  datepickerClassNames: "top-12",
  defaultDate: new Date("2022-01-01"),
  language: "pt-BR",
};

export function DialogColaborador() {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("default@default.com");
  const [senha, setSenha] = useState("1234");
  const [telefone, setTelefone] = useState("");
  const [endereco, setEndereco] = useState("");
  const [altura, setAltura] = useState("");
  const [cpf, setCpf] = useState("");
  const [etnia, setEtnia] = useState("");
  const [tatuagem, setTatuagem] = useState(false);
  const [piercing, setPiercing] = useState(false);
  const [barba, setBarba] = useState(false);
  const [numeroCalcado, setNumeroCalcado] = useState("");
  const [salto, setSalto] = useState(false);
  const [aparelho, setAparelho] = useState(false);
  const [qntTrabalhados, setQntTrabalhados] = useState(0);
  const [numeracaoRoupa, setNumeracaoRoupa] = useState("");
  const [dadosBancarios, setDadosBancarios] = useState("");
  const [nivelAcesso, setNivelAcesso] = useState();
  const [dataNascimento, setDataNascimento] = useState("");
  const [descricao, setDescricao] = useState("teste");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const [imagem1, setImagem1] = useState("");
  const [imagem2, setImagem2] = useState("");
  const [imagem3, setImagem3] = useState("");

  const handleImage1 = (image) => setImagem1(image);
  const handleImage2 = (image) => setImagem2(image);
  const handleImage3 = (image) => setImagem3(image);

  const handleSucessClick = () => {
    setShowSuccessAlert(true);
    const listImages = [];
    imagem1 != null ? listImages.push(imagem1) : console.clear();
    imagem2 != null ? listImages.push(imagem2) : console.clear();
    imagem3 != null ? listImages.push(imagem3) : console.clear();
    CreateColaboradores(
      nome,
      email,
      senha,
      telefone,
      endereco,
      altura,
      cpf,
      descricao,
      etnia,
      tatuagem,
      piercing,
      barba,
      numeroCalcado,
      salto,
      aparelho,
      qntTrabalhados,
      numeracaoRoupa,
      dadosBancarios,
      nivelAcesso,
      dataNascimento,
      listImages
    );
    setTimeout(() => {
      setShowSuccessAlert(false);
      setOpen(!open);
    }, 2000);
  };

  const handleChange = (selectedDate) => {
    setDataNascimento(selectedDate);
  };
  const handleClose = (state) => {
    setShow(state);
  };
  const handleOpen = () => setOpen(!open);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      setUploadedImage(reader.result);
      setBase64Image(reader.result.split(",")[1]);
    };

    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <div className="hidden sm:hidden lg:flex" onClick={handleOpen}>
        <Button className="flex items-center gap-3" color="orange" size="sm">
          <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Adicionar membro
          de elenco
        </Button>
      </div>
      <div className="block lg:hidden sm:flex" onClick={handleOpen}>
        <Button className="flex items-center gap-3" color="orange" size="sm">
          <UserPlusIcon strokeWidth={2} className="h-4 w-4" />
        </Button>
      </div>
      <Dialog
        className="bg-[#1e1e1e] "
        open={open}
        size="md"
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader className="text-white opacity-80">
          Cadastrar membro de elenco
        </DialogHeader>
        <DialogBody divider className=" max-h-[400px] overflow-y-auto">
          <div className="flex justify-center gap-5">
            <div>
              <div className="w-full lg:hidden mb-5">
                <div className="flex justify-center">
                  <Avatar
                    src={
                      uploadedImage != null
                        ? uploadedImage
                        : `${urlApi}/media/colaboradores/imageDefault.png`
                    }
                    alt="avatar"
                    className="w-[160px] h-[160px]"
                  />
                </div>

                <div
                  {...getRootProps()}
                  className={`dropzone ${
                    isDragActive ? "active" : ""
                  } mt-7 flex justify-center`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Button
                      color="orange"
                      variant="gradient"
                      className="flex items-center gap-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-5 w-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                        />
                      </svg>
                      Arraste a imagem aqui
                    </Button>
                  ) : (
                    <Button
                      color="orange"
                      variant="gradient"
                      className="flex items-center gap-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-5 w-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                        />
                      </svg>
                      Selecionar Imagem
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex flex-col justify-center w-[330px] lg:w-[750px] gap-5 lg:justify-start lg:flex-row">
                <div className="w-full lg:w-72 ">
                  <Input
                    required
                    label="Nome"
                    className="text-white"
                    color="orange"
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-72">
                  <Input
                    required
                    label="Endereço"
                    className="text-white"
                    color="orange"
                    onChange={(e) => setEndereco(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-72 lg:w-48">
                  <ReactInputMask
                    required
                    className="peer h-full w-full rounded-[7px] placeholder:text-blue-gray-500 border border-blue-gray-200  bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    mask="(99) 99999-9999"
                    placeholder="Telefone"
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center w-[330px] lg:w-[750px] gap-5 lg:justify-start lg:flex-row mt-5">
                <div className="w-full md:w-72 h-10 flex gap-4">
                  <Input
                    required
                    label="Email"
                    className="text-white"
                    color="orange"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-72 h-10 flex gap-4">
                  <Input
                    required
                    type="password"
                    label="Senha"
                    className="text-white"
                    color="orange"
                    onChange={(e) => setSenha(e.target.value)}
                  />
                </div>
                <div className="w-full lg:w-72">
                  <Select
                    onChange={(e) => setNivelAcesso(e)}
                    label="Nível de acesso"
                    className="text-white"
                    color="orange"
                  >
                    <Option value="Moderador">Moderador</Option>
                    <Option value="Colaborador">Membro de elenco</Option>
                  </Select>
                </div>
              </div>
              <div className="flex flex-col justify-center w-[330px] lg:w-[750px] gap-5 lg:justify-start lg:flex-row mt-5">
                <div className="w-full md:w-72 lg:w-32">
                  <ReactInputMask
                    required
                    className="peer h-full w-full rounded-[7px] placeholder:text-blue-gray-500 border border-blue-gray-200 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    mask="999.999.999-99"
                    placeholder="CPF"
                    onChange={(e) => setCpf(e.target.value)}
                  />
                </div>
                <div className="w-full md:w-72 lg:w-24">
                  <ReactInputMask
                    className="peer h-full w-full rounded-[7px] placeholder:text-blue-gray-500 border border-blue-gray-200 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    mask="9,99m"
                    placeholder="Altura"
                    onChange={(e) => setAltura(e.target.value)}
                  />
                </div>
                <div>
                  <Datepicker
                    options={options}
                    onChange={handleChange}
                    show={show}
                    setShow={handleClose}
                  />
                </div>
                <div className="w-full lg:w-72">
                  <Select
                    onChange={(e) => setEtnia(e)}
                    value={etnia}
                    label="Cor de Pele"
                    className="text-white"
                    color="orange"
                  >
                    <Option value="Branca">Pele Branca</Option>
                    <Option value="Morena clara">Pele Morena clara</Option>
                    <Option value="Morena Escura">Pele Morena Escura</Option>
                    <Option value="Amarela">Pele Amarela</Option>
                    <Option value="Preta">Pele Preta</Option>
                  </Select>
                </div>
              </div>
              <div className="flex flex-col justify-center w-[330px] lg:w-[750px] gap-5 lg:justify-start lg:flex-row mt-5">
                <div className="w-full md:w-72 lg:w-52">
                  <Input
                    label="Numeração da roupa"
                    className="text-white"
                    color="orange"
                    onChange={(e) => setNumeracaoRoupa(e.target.value)}
                  />
                </div>
                <div className="flex gap-2">
                  <div className="w-[100px] md:w-72 lg:w-12 h-10 flex gap-4">
                    <ReactInputMask
                      className="peer h-full w-full rounded-[7px] placeholder:text-blue-gray-500 border border-blue-gray-200 bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      mask="99"
                      onChange={(e) => setNumeroCalcado(e.target.value)}
                    />
                  </div>
                  <div className="flex  items-center">
                    <label className="text-white inline-block">
                      Numeração do Calçado
                    </label>
                  </div>
                </div>
                <div className="flex justify-between">
                  <Checkbox
                    onChange={(e) => setBarba(e.target.checked)}
                    color="orange"
                    className="text-black"
                    labelProps={{ className: "text-white" }}
                    label="Barba?"
                  />
                  <Checkbox
                    onChange={(e) => setTatuagem(e.target.checked)}
                    color="orange"
                    className="text-black"
                    labelProps={{ className: "text-white" }}
                    label="Tatuagem?"
                  />
                  <Checkbox
                    onChange={(e) => setAparelho(e.target.checked)}
                    color="orange"
                    className="text-black lg:hidden"
                    labelProps={{ className: "text-white lg:hidden" }}
                    label="Aparelho?"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center w-[330px] lg:w-[750px] gap-5 lg:justify-start lg:flex-row mt-5">
                <div className="flex justify-between">
                  <Checkbox
                    onChange={(e) => setPiercing(e.target.checked)}
                    color="orange"
                    className="text-black"
                    labelProps={{ className: "text-white" }}
                    label="Piercing?"
                  />
                  <Checkbox
                    onChange={(e) => setAparelho(e.target.checked)}
                    color="orange"
                    className="hidden text-black lg:block"
                    labelProps={{ className: "text-white hidden lg:block" }}
                    label="Aparelho?"
                  />
                  <Checkbox
                    onChange={(e) =>
                      e.target.checked == true
                        ? setQntTrabalhados(1)
                        : setDadosBancarios(0)
                    }
                    color="orange"
                    className="text-black"
                    labelProps={{ className: "text-white" }}
                    label="Já trabalhou conosco?"
                  />
                </div>
                <div className="flex justify-between">
                  <Checkbox
                    onChange={(e) => setSalto(e.target.checked)}
                    color="orange"
                    className="text-black"
                    labelProps={{ className: "text-white" }}
                    label="Usa Salto?"
                  />
                </div>
              </div>
              <div className="w-full flex justify-between">
                <ButtonforImage setImage={handleImage1} />
                <ButtonforImage setImage={handleImage2} />
                <ButtonforImage setImage={handleImage3} />
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row gap-5 justify-start mt-5">
            <Textarea
              className="h-10 text-white"
              label="Dados bancários"
              color="orange"
              onChange={(e) => setDadosBancarios(e.target.value)}
            />
            <Textarea
              className="h-10 text-white"
              label="Descrição"
              color="orange"
              onChange={(e) => setDescricao(e.target.value)}
            />
          </div>
          {showSuccessAlert && (
            <div className="bg-green-500 text-white p-2 mt-4 rounded">
              <p>Operação realizada com sucesso!</p>
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancelar</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleSucessClick}>
            <span>Confirmar</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
