import React, {useState} from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Textarea,
    Typography,
} from '@material-tailwind/react'
import { UserPlusIcon } from '@heroicons/react/24/solid';
import ButtonForImage from '../../../global/ButtonForImage';
import CreateFantasia from '../../../controller/fantasias/CreateFantasia';
import Swal from 'sweetalert2';

export default function DialogCreateFantasia() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);
    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");
    const [musicaTema, setMusicaTema] = useState("");
    const [videos, setVideos] = useState();
    const [videoMaquiagem, setVideoMaquiagem] = useState();
    const [localDaPeca, setLocalDaPeca] = useState();
    const [image1, setImage1] = useState();
    const [image2, setImage2] = useState();
    const [image3, setImage3] = useState();
    const [image4, setImage4] = useState();
    const [image5, setImage5] = useState();
    const [image6, setImage6] = useState();
    const [image7, setImage7] = useState();
    const [image8, setImage8] = useState();

    const handleImage1 = (newImage) => setImage1(newImage);
    const handleImage2 = (newImage) => setImage2(newImage);
    const handleImage3 = (newImage) => setImage3(newImage);
    const handleImage4 = (newImage) => setImage4(newImage);
    const handleImage5 = (newImage) => setImage5(newImage);
    const handleImage6 = (newImage) => setImage6(newImage);
    const handleImage7 = (newImage) => setImage7(newImage);
    const handleImage8 = (newImage) => setImage8(newImage);


  return (
    <>
        <div className="hidden sm:hidden lg:flex" onClick={handleOpen}>
            <Button className="flex items-center gap-3" color="orange" size="sm">
                <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Adicionar personagem
            </Button>
        </div>
        <div className="block lg:hidden sm:flex" onClick={handleOpen}>
            <Button className="flex items-center gap-3" color="orange" size="sm">
                <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> 
            </Button>
        </div>
        { open &&
            <Dialog
            className='bg-[#1e1e1e] '
            open={open}
            size={"xl"}
            handler={handleOpen}
                >
                <DialogHeader className="text-white opacity-80 overflow-auto">
                    Cadastrar Personagem
                </DialogHeader>
                <DialogBody divider className=" flex flex-col gap-5 max-h-[400px] overflow-y-auto">
                    <div className='w-full flex '>
                        <div className="w-full ">
                            <Input required onChange={(e) => setNome(e.target.value)} label="Nome" className="text-white" color="orange"/>
                        </div>   
                    </div>
                    <div className='w-full flex gap-5 hidden lg:flex'> 
                        <div className="w-full ">
                            <Input required  onChange={(e) => setLocalDaPeca(e.target.value)} label="Local da peça" className="text-white" color="orange"/>
                        </div>   
                        <div className="w-full">
                            <Input required  onChange={(e) => setMusicaTema(e.target.value)} label="Musica Tema" className="text-white" color="orange"/>
                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-5 lg:hidden'> 
                        <div className="w-full ">
                            <Input required  onChange={(e) => setLocalDaPeca(e.target.value)} label="Local da peça" className="text-white" color="orange"/>
                        </div>   
                        <div className="w-full">
                            <Input required  onChange={(e) => setMusicaTema(e.target.value)} label="Musica Tema" className="text-white" color="orange"/>
                        </div>
                    </div>
                    <div className='w-full flex gap-5 hidden lg:flex'>
                        <div className="w-full ">
                            <Input required onChange={(e) => setVideos(e.target.value)} label="Link video do figurino" className="text-white" color="orange"/>
                        </div>   
                        <div className="w-full">
                            <Input required onChange={(e) => setVideoMaquiagem(e.target.value)} label="Link video da maquiagem" className="text-white" color="orange"/>
                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-5 lg:hidden'>
                        <div className="w-full ">
                            <Input required  onChange={(e) => setVideos(e.target.value)} label="Link video do figurino" className="text-white" color="orange"/>
                        </div>   
                        <div className="w-full">
                            <Input required onChange={(e) => setVideoMaquiagem(e.target.value)} label="Link video da maquiagem" className="text-white" color="orange"/>
                        </div>
                    </div>
                    <Textarea onChange={(e) => setDescricao(e.target.value)} className="h-10 text-white" label="Descrição" color="orange"/>    
                    <Typography color="white" className="ml-3  hidden lg:flex" >
                        Fotos do Personagem
                    </Typography>
                    {/*PARA DESKTOP*/}
                    <div className='flex justify-between hidden lg:flex'>
                        <ButtonForImage onImage={handleImage1}/>
                        <ButtonForImage onImage={handleImage2}/>
                        <ButtonForImage onImage={handleImage3}/>
                        <ButtonForImage onImage={handleImage4}/>
                    </div>   
                    <Typography color="white" className="ml-3  hidden lg:flex" >
                        Fotos da maquiagem
                    </Typography>
                    <div className='flex justify-between hidden lg:flex'>
                        <ButtonForImage onImage={handleImage5}/>
                        <ButtonForImage onImage={handleImage6}/>
                        <ButtonForImage onImage={handleImage7}/>
                        <ButtonForImage onImage={handleImage8}/>
                    </div>   
                    {/*PARA MOBILE*/}
                    <div className='flex flex-col lg:hidden'>
                        <Typography color="white" className="ml-3 lg:hidden" >
                            Fotos do Personagem
                        </Typography>
                        <ButtonForImage onImage={handleImage1}/>
                        <ButtonForImage onImage={handleImage2}/>
                        <ButtonForImage onImage={handleImage3}/>
                        <ButtonForImage onImage={handleImage4}/>
                        <Typography color="white" className="ml-3 lg:hidden" >
                            Fotos da maquiagem
                        </Typography>
                        <ButtonForImage onImage={handleImage5}/>
                        <ButtonForImage onImage={handleImage6}/>
                        <ButtonForImage onImage={handleImage7}/>
                        <ButtonForImage onImage={handleImage8}/>
                    </div>

                </DialogBody>
                <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Cancelar</span>
                </Button>
                <Button variant="gradient" color="green" onClick={() => {
                    const listImagesFantasias = []
                    const listImagesMaquiagem = []

                    image1 == null ? console.log("") : listImagesFantasias.push(image1)
                    image2 == null ? console.log("")  : listImagesFantasias.push(image2)
                    image3 == null ? console.log("")  : listImagesFantasias.push(image3)
                    image4 == null ? console.log("")  : listImagesFantasias.push(image4)
                    image5 == null ? console.log("")  : listImagesMaquiagem.push(image5)
                    image6 == null ? console.log("")  : listImagesMaquiagem.push(image6)
                    image7 == null ? console.log("")  : listImagesMaquiagem.push(image7)
                    image8 == null ? console.log("")  : listImagesMaquiagem.push(image8)

                    CreateFantasia(
                        nome,
                        descricao,
                        listImagesFantasias,
                        listImagesMaquiagem,
                        musicaTema,
                        localDaPeca,
                        videos,
                        videoMaquiagem
                    )
                    setOpen(!open)
                    Swal.fire({
                        title: "Bom trabalho!",
                        color: "#fff",
                        html: "Você criou um novo personagem!",
                        icon: "success",
                        showConfirmButton: false,
                        background: '#1e1e1e',
                        timer: 1500,
                        timerProgressBar: true,
                      });

                }}>
                    <span>Confirmar</span>
                </Button>
                </DialogFooter>
            </Dialog>
            }
    </>    
  )
}
