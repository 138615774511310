import axios from "axios"
import urlApi from "../../urlApi";

function LoginController (email, password) {

    function saveTokenToLocalStorage(token, id){
        localStorage.setItem('token', token);
        localStorage.setItem('id', id)
      };

      
    const url = `${urlApi}/`
    const data = {
        email: email,
        senha: password 
    }

    // const response = axios.post(url, data).then(response => {
    //     const token = response.data.token;
    //     const id = response.data.id;
    //     saveTokenToLocalStorage(token, id);
    //     return 200
    // }).catch(error => {
    //     return 401;
    // })
    const response = axios.get(url).then(response => {
        console.log("Foiiii")
    }).catch(error => {
        return 401;
    })
    return response;
}

export default LoginController