import { Spinner } from "@material-tailwind/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DialogViewFantasia from "../../../Fantasias/components/DialogViewFantasia";
import urlApi from "../../../../urlApi";

export default function AvatarFantasia({ id }) {
  const [dataFantasia, setDataFantasia] = useState();
  const [fantasiaImage, setFantasiaImage] = useState([]);
  const [maquiagemImage, setMaquiagemImage] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        `${urlApi}/oneFantasia/${id}`
      );

      setDataFantasia(response.data);
      const listImagens = response.data.imagens.split(",");
      listImagens.splice(0, 1);
      setFantasiaImage(listImagens);
      const listImagensMaquiagem = response.data.imagem_maquiagem.split(",");
      listImagensMaquiagem.splice(0, 1);
      setMaquiagemImage(listImagensMaquiagem);

      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner color="orange" />
      ) : (
        <DialogViewFantasia
          nome={dataFantasia.nome}
          listImagens={fantasiaImage}
          listImagensMaquiagem={maquiagemImage}
          descricao={dataFantasia.descricao}
          isEvento={true}
        />
      )}
    </>
  );
}
