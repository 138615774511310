import React, { useState } from "react";
import logo from "../../global/logo-leonardo.png";
import loginGif from "./login.gif";

import { Button, Input, Typography } from "@material-tailwind/react";
import LoginController from "../../controller/autenticacao/Login";
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verify, setVerify] = useState(false);
  const navigate = useNavigate();

  return (
    <section className="h-screen flex justify-center">
      <div className="container h-full px-6 py-24">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
          {/* <!-- Left column container with background--> */}
          <div className="hidden lg:block mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
            <img src={loginGif} />
          </div>

          {/* <!-- Right column container with form --> */}
          <div className="flex flex-col md:w-8/12 lg:ml-6 lg:w-5/12 gap-5 justify-center items-center">
            <img src={logo} className="w-48" alt="Logo" />

            <Typography>

          </Typography>

            <form className="flex flex-col gap-5 items-center">
              <div className="w-80 lg:w-96 ">
                <Input
                  required
                  label="Email"
                  className="text-white"
                  color={verify ? "red" : "orange"}
                  onChange={(event) => setEmail(event.target.value)}
                />
            </div>

              <div className="w-80 lg:w-96 ">
                <Input
                  required
                  type="password"
                  label="senha"
                  className="text-white"
                  color={verify ? "red" : "orange"}
                  onChange={(event) => setPassword(event.target.value)}
                />  
              </div>
              {verify ? <Typography color="red">
                Usuário ou senha incorreta!
                Tente novamente.
              </Typography> : null}
              <Button onClick={ async () => {
                const response = await LoginController(email, password)
                if(response === 200){
                  navigate('/');
                }else{
                  setVerify(true)
                }
              }} color="orange" className="w-80 lg:w-96">
                Login
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
