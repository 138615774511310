import axios from "axios";
import urlApi from "../../urlApi";

const AtivarColaborador = async(id) => {
    const url = `${urlApi}/ativarColaborador/${id}`;
    try{
        const response = await axios.get(url);
        if(response.status === 200){
            return true;
        }else{
            return false;
        }
    }catch(error){
        console.error(error)
        return false;
    }
}

export default AtivarColaborador;