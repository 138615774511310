import React from "react";
import { Stepper, Step, Button, Typography } from "@material-tailwind/react";
import {
  CogIcon,
  BuildingLibraryIcon,
  RocketLaunchIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import useEventoContext from "../../../hook/useEventoContext";
import CreateEvento from "../../../controller/eventos/CreateEvento";
import EditEvento from "../../../controller/eventos/EditEvento";

export function StepperEventos({id, isEdit}) {
  const {
    setIsFirstStep,
    setActiveStep,
    setOpen,
    activeStep,
    isLastStep,
    isFirstStep,
    selectedColab,
    selectedFantasia,
    idadeAniversariante,
    horarioDoBolo,
    descricao,
    nomeAniversariante,
    horarioFinal,
    selectedDate,
    image,
    tituloEvento,
    nomeContratante,
    endereco,
    telefoneContato,
    horarioElenco,
    pagamento
  } = useEventoContext();

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => {
    !isFirstStep && setActiveStep((cur) => cur - 1);
  };

  return (
    <div className="w-full px-0 py-0 lg:px-24 lg:py-4">
      <Stepper
        activeStep={activeStep}
        activeLineClassName="bg-orange-500"
        // isLastStep={(value) => setIsLastStep(value)}
        isFirstStep={(value) => setIsFirstStep(value)}
      >
        <Step
          // onClick={() => setActiveStep(0)}
          activeClassName="bg-orange-700"
          completedClassName="bg-orange-500"
        >
          <RocketLaunchIcon className="h-5 w-5" />
          <div className="hidden lg:block  absolute -bottom-[4.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 0 ? "orange" : "gray"}
            >
              Etapa 1
            </Typography>
            <Typography
              color={activeStep === 0 ? "orange" : "gray"}
              className="font-normal"
            >
              Informações principais
            </Typography>
          </div>
        </Step>
        <Step
          // onClick={() => setActiveStep(1)}
          activeClassName="bg-orange-700"
          completedClassName="bg-orange-500"
        >
          <CogIcon className="h-5 w-5" />
          <div className="hidden lg:block  absolute -bottom-[4.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 1 ? "orange" : "gray"}
            >
              Etapa 2
            </Typography>
            <Typography
              color={activeStep === 1 ? "orange" : "gray"}
              className="font-normal"
            >
              Informações complementares
            </Typography>
          </div>
        </Step>
        <Step
          // onClick={() => setActiveStep(2)}
          activeClassName="bg-orange-700"
          completedClassName="bg-orange-500"
        >
          <BuildingLibraryIcon className="h-5 w-5" />
          <div className="hidden lg:block  absolute -bottom-[4.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 2 ? "orange" : "gray"}
            >
              Etapa 3
            </Typography>
            <Typography
              color={activeStep === 2 ? "orange" : "gray"}
              className="font-normal"
            >
              Escolha seus personagens
            </Typography>
          </div>
        </Step>
        <Step
          // onClick={() => setActiveStep(3)}
          activeClassName="bg-orange-700"
          completedClassName="bg-orange-500"
        >
          <UserGroupIcon className="h-5 w-5" />
          <div className="hidden lg:block absolute -bottom-[4.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 3 ? "orange" : "gray"}
            >
              Etapa 4
            </Typography>
            <Typography
              color={activeStep === 3 ? "orange" : "gray"}
              className="font-normal"
            >
              Escolha seus membros de elenco
            </Typography>
          </div>
        </Step>
      </Stepper>
      <div className="mt-10 lg:mt-28 flex justify-between">
        <Button color="orange" onClick={handlePrev} disabled={isFirstStep}>
          Anterior
        </Button>
        {activeStep === 3 ? (
          <Button
            color="orange"
            onClick={() => {
              if(isEdit){
                EditEvento(
                  selectedColab,
                  selectedFantasia,
                  idadeAniversariante,
                  horarioDoBolo,
                  descricao,
                  nomeAniversariante,
                  horarioFinal,
                  selectedDate,
                  image,
                  tituloEvento,
                  nomeContratante,
                  endereco,
                  telefoneContato,
                  horarioElenco,
                  setOpen,
                  id
                );
              }else{
                CreateEvento(
                  selectedColab,
                  selectedFantasia,
                  idadeAniversariante,
                  horarioDoBolo,
                  descricao,
                  nomeAniversariante,
                  horarioFinal,
                  selectedDate,
                  image,
                  tituloEvento,
                  nomeContratante,
                  endereco,
                  telefoneContato,
                  horarioElenco,
                  setOpen,
                  pagamento
                );
              }
            
             
            }}
            disabled={isFirstStep}
          >
            Confirmar
          </Button>
        ) : (
          <Button color="orange" onClick={handleNext} disabled={isLastStep}>
            Próximo
          </Button>
        )}
      </div>
    </div>
  );
}
