import { Avatar, Typography } from "@material-tailwind/react";
import React from "react";
import urlApi from "../../../../urlApi";

export default function CardFantasia({imagem, descricao, content}) {
  return (
    <div className="flex flex-col bg-transparent mt-2 rounded-[20px]">
      <div className="flex items-start gap-4 ">
        <Avatar src={`${urlApi}/media/fantasias/${imagem}`} alt="avatar" className="z-40" />
        <div>
          <Typography color="white">{content}</Typography>
          <Typography
            variant="small"
            className="font-normal text-gray-400 truncate"
          >
            {descricao}
          </Typography>
        </div>
      </div>
    </div>
  );
}
