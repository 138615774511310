import React, {useState} from 'react'
import axios from 'axios';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Textarea,
    Tooltip,
    Typography,
    IconButton,
} from '@material-tailwind/react'
import { PencilIcon } from '@heroicons/react/24/solid';
import ButtonForImage from '../../../global/ButtonForImage';
import EditFantasia from '../../../controller/fantasias/EditFantasia';
import urlApi from '../../../urlApi';

export default function DialogEditFantasia({id}) {
    const [open, setOpen] = useState(false);
    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");
    const [musicaTema, setMusicaTema] = useState("");
    const [videos, setVideos] = useState();
    const [videoMaquiagem, setVideoMaquiagem] = useState();
    const [localDaPeca, setLocalDaPeca] = useState();
    const [image1, setImage1] = useState();
    const [image2, setImage2] = useState();
    const [image3, setImage3] = useState();
    const [image4, setImage4] = useState();
    const [image5, setImage5] = useState();
    const [image6, setImage6] = useState();
    const [image7, setImage7] = useState();
    const [image8, setImage8] = useState();
    const [listFantasias, setFantasias] = useState();
    const [listMaquiagens, setMaquiagens] = useState();

    const handleOpen = async () => {
        await fetchData();
        setOpen(!open);
    }
    const handleImage1 = (newImage) => setImage1(newImage);
    const handleImage2 = (newImage) => setImage2(newImage);
    const handleImage3 = (newImage) => setImage3(newImage);
    const handleImage4 = (newImage) => setImage4(newImage);
    const handleImage5 = (newImage) => setImage5(newImage);
    const handleImage6 = (newImage) => setImage6(newImage);
    const handleImage7 = (newImage) => setImage7(newImage);
    const handleImage8 = (newImage) => setImage8(newImage);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${urlApi}/oneFantasia/${id}`);
            const listImagensFantasia = response.data.imagens.split(",")
            const listImagensMaquiagem = response.data.imagem_maquiagem.split(",")
            setFantasias(listImagensFantasia);
            setMaquiagens(listImagensMaquiagem);
            setNome(response.data.nome);
            setDescricao(response.data.descricao);
            setLocalDaPeca(response.data.local);
            setMusicaTema(response.data.musica_tema);
            setVideoMaquiagem(response.data.video_maquiagem);
            setVideos(response.data.videos);
            setImage1(listImagensFantasia[1])
            setImage2(listImagensFantasia[2])
            setImage3(listImagensFantasia[3])
            setImage4(listImagensFantasia[4])
            setImage5(listImagensMaquiagem[1])
            setImage6(listImagensMaquiagem[2])
            setImage7(listImagensMaquiagem[3])
            setImage8(listImagensMaquiagem[4])
            
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    };
    
  return (
    <>
        <Tooltip content="Editar Personagem">
            <IconButton  onClick={handleOpen} variant="text" color="white">
                <PencilIcon className="h-5 w-5" />
            </IconButton>
        </Tooltip>
        { open &&
            <Dialog
            className='bg-[#1e1e1e] '
            open={open}
            size={"xl"}
            handler={handleOpen}
            >
                <DialogHeader className="text-white opacity-80 overflow-auto">
                    Cadastrar personagem
                </DialogHeader>
                <DialogBody divider className=" flex flex-col gap-5 max-h-[400px] overflow-y-auto">
                    <div className='w-full flex '>
                        <div className="w-full ">
                            <Input required value={nome} onChange={(e) => setNome(e.target.value)} label="Nome" className="text-white" color="orange"/>
                        </div>   
                    </div>
                    <div className='w-full flex gap-5 hidden lg:flex'> 
                        <div className="w-full ">
                            <Input required value={localDaPeca} onChange={(e) => setLocalDaPeca(e.target.value)} label="Local da peça" className="text-white" color="orange"/>
                        </div>   
                        <div className="w-full">
                            <Input required value={musicaTema} onChange={(e) => setMusicaTema(e.target.value)} label="Musica Tema" className="text-white" color="orange"/>
                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-5 lg:hidden'> 
                        <div className="w-full ">
                            <Input required value={localDaPeca} onChange={(e) => setLocalDaPeca(e.target.value)} label="Local da peça" className="text-white" color="orange"/>
                        </div>   
                        <div className="w-full">
                            <Input required value={musicaTema} onChange={(e) => setMusicaTema(e.target.value)} label="Musica Tema" className="text-white" color="orange"/>
                        </div>
                    </div>
                    <div className='w-full flex gap-5 hidden lg:flex'>
                        <div className="w-full ">
                            <Input required value={videos} onChange={(e) => setVideos(e.target.value)} label="Link video do figurino" className="text-white" color="orange"/>
                        </div>   
                        <div className="w-full">
                            <Input required value={videoMaquiagem} onChange={(e) => setVideoMaquiagem(e.target.value)} label="Link video da maquiagem" className="text-white" color="orange"/>
                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-5 lg:hidden'>
                        <div className="w-full ">
                            <Input required value={videos} onChange={(e) => setVideos(e.target.value)} label="Link video do figurino" className="text-white" color="orange"/>
                        </div>   
                        <div className="w-full">
                            <Input required value={videoMaquiagem} onChange={(e) => setVideoMaquiagem(e.target.value)} label="Link video da maquiagem" className="text-white" color="orange"/>
                        </div>
                    </div>
                    <Textarea onChange={(e) => setDescricao(e.target.value)} value={descricao} className="h-10 text-white" label="Descrição" color="orange"/>    
                    <Typography color="white" className="ml-3  hidden lg:flex" >
                        Fotos do personagem
                    </Typography>
                    {/*PARA DESKTOP*/}
                    <div className='flex justify-between hidden lg:flex'>
                        <ButtonForImage onImage={handleImage1} image={image1}/>
                        <ButtonForImage onImage={handleImage2} image={image2}/>
                        <ButtonForImage onImage={handleImage3} image={image3}/>
                        <ButtonForImage onImage={handleImage4} image={image4}/>
                    </div>   
                    <Typography color="white" className="ml-3  hidden lg:flex" >
                        Fotos da maquiagem
                    </Typography>
                    <div className='flex justify-between hidden lg:flex'>
                        <ButtonForImage onImage={handleImage5} image={image5}/>
                        <ButtonForImage onImage={handleImage6} image={image6}/>
                        <ButtonForImage onImage={handleImage7} image={image7}/>
                        <ButtonForImage onImage={handleImage8} image={image8}/>
                    </div>   
                    {/*PARA MOBILE*/}
    
                    <div className='flex flex-col lg:hidden'>
                        <Typography color="white" className="ml-3 lg:hidden" >
                            Fotos do personagem
                        </Typography>
                        <ButtonForImage onImage={handleImage1} image={image1}/>
                        <ButtonForImage onImage={handleImage2} image={image2}/>
                        <ButtonForImage onImage={handleImage3} image={image3}/>
                        <ButtonForImage onImage={handleImage4} image={image4}/>
                        <Typography color="white" className="ml-3 lg:hidden" >
                            Fotos da maquiagem
                        </Typography>
                        <ButtonForImage onImage={handleImage5} image={image5}/>
                        <ButtonForImage onImage={handleImage6} image={image6}/>
                        <ButtonForImage onImage={handleImage7} image={image7}/>
                        <ButtonForImage onImage={handleImage8} image={image8}/>
                    </div>
                    

                </DialogBody>
                <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                <Button variant="gradient" color="green" onClick={() => {
                    const listImagesFantasias = []
                    const listImagesMaquiagem = []

                    image1 == null || image1.length < 20 ? console.log("") : listImagesFantasias.push(`${listFantasias[1]},${image1}`)
                    image2 == null || image2.length < 20 ? console.log("")  : listImagesFantasias.push(`${listFantasias[2]},${image2}`)
                    image3 == null || image3.length < 20 ? console.log("")  : listImagesFantasias.push(`${listFantasias[3]},${image3}`)
                    image4 == null || image4.length < 20 ? console.log("")  : listImagesFantasias.push(`${listFantasias[4]},${image4}`)
                    image5 == null || image5.length < 20 ? console.log("")  : listImagesMaquiagem.push(`${listMaquiagens[1]},${image5}`)
                    image6 == null || image6.length < 20 ? console.log("")  : listImagesMaquiagem.push(`${listMaquiagens[2]},${image6}`)
                    image7 == null || image7.length < 20 ? console.log("")  : listImagesMaquiagem.push(`${listMaquiagens[3]},${image7}`)
                    image8 == null || image8.length < 20 ? console.log("")  : listImagesMaquiagem.push(`${listMaquiagens[5]},${image8}`)

                    EditFantasia(
                        id,
                        nome,
                        descricao,
                        listImagesFantasias,
                        listImagesMaquiagem,
                        musicaTema,
                        localDaPeca,
                        videos,
                        videoMaquiagem
                    )
                    setOpen(!open)
                }}>
                    <span>Confirm</span>
                </Button>
                </DialogFooter>
            </Dialog>
            }
    </>    
  )
}
