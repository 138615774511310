import { Input, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CardAvatar } from "./components/CardAvatar";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import useEventoContext from "../../../../hook/useEventoContext";
import urlApi from "../../../../urlApi";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

const Step3 = ({isEdit}) => {
  const {
    setSelectedFantasia,
    selectedFantasia,
    idEvento,
    itemsFantasia,
    setItemsFantasia,
  } = useEventoContext();
  const [pesquisar, setPesquisar] = useState("");
  const [pesquisarSelected, setPesquisarSelected] = useState("");
  
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const reorderedItems = reorder(
        source.droppableId === "droppable" ? itensFiltrados : selectedFiltrados,
        source.index,
        destination.index
      );

      if (source.droppableId === "droppable") {
        setItemsFantasia(reorderedItems);
      } else {
        setSelectedFantasia(reorderedItems);
      }
    } else {
      const result = move(
        source.droppableId === "droppable" ? itensFiltrados : selectedFiltrados,
        destination.droppableId === "droppable"
          ? itensFiltrados
          : selectedFiltrados,
        source,
        destination
      );

      setItemsFantasia(result.droppable);
      setSelectedFantasia(result.droppable2);
    }
  };

  const itensFiltrados = itemsFantasia.filter((item) =>
    item.nome.toLowerCase().includes(pesquisar)
  );
  const selectedFiltrados = selectedFantasia.filter((item) =>
    item.nome.toLowerCase().includes(pesquisarSelected)
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex flex-col lg:flex-row gap-5 ">
        <Droppable droppableId="droppable">
          {(provided) => (
            <div className="flex flex-col w-full h-48 lg:h-full lg:w-[50vw]">
              <Typography color="white">Seus Personagens</Typography>
              <div
                ref={provided.innerRef}
                className="flex flex-col bg-[#2d2d2d] gap-3 p-3  h-[240px] overflow-y-auto rounded"
              >
                <div className="w-full ">
                  <Input
                    onChange={(e) => {
                      setPesquisar(e.target.value);
                    }}
                    label="Pesquisar"
                    color="orange"
                    className="text-white"
                    icon={
                      <MagnifyingGlassIcon className="h-5 w-5" color="white" />
                    }
                  />
                </div>
                {itensFiltrados.map((item, index) => (
                  <Draggable
                    key={`${item.id}`}
                    draggableId={`${item.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <CardAvatar
                        content={item.nome}
                        provided={provided}
                        descricao={item.descricao}
                        imagem={`${urlApi}/media/fantasias/${item.imagens[0]}`}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
        <Droppable droppableId="droppable2">
          {(provided) => (
            <div className="flex flex-col w-full h-48 lg:h-full lg:w-[50vw]">
              <Typography color="white">Personagens escolhidos</Typography>
              <div
                ref={provided.innerRef}
                className="flex flex-col bg-[#2d2d2d] gap-3 p-3 h-[240px] overflow-y-auto rounded "
              >
                <div className="w-full ">
                  <Input
                    label="Pesquisar"
                    onChange={(e) => {
                      setPesquisarSelected(e.target.value)
                    }}
                    color="orange"
                    className="text-white"
                    icon={
                      <MagnifyingGlassIcon className="h-5 w-5" color="white" />
                    }
                  />
                </div>

                {selectedFiltrados.map((item, index) => (
                  <Draggable
                    key={`${item.id}`}
                    draggableId={`${item.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <CardAvatar
                        content={item.nome}
                        provided={provided}
                        idEvento={idEvento}
                        id={item.id}
                        descricao={item.descricao}
                        isEdit={isEdit}
                        imagem={`${urlApi}/media/fantasias/${item.imagens[0]}`}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default Step3;
