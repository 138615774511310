import {
  Card,
  Chip,
  Button,
  Input,
  Checkbox,
  Typography,
} from "@material-tailwind/react";
import axios from "axios";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AvatarColaboradores from "./components/AvatarAtor";
import AvatarFantasia from "./components/AvatarFantasia";
import Swal from "sweetalert2";
import DeleteEvento from "../../../controller/eventos/DeleteEvento";
import { useNavigate } from "react-router-dom";
import EncerrarEvento from "../../../controller/eventos/EncerrarEvento";
import DialogEditEventos from "../components/DialogEditEventos";
import urlApi from "../../../urlApi";

export default function ViewEvento() {
  const { id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [descricao, setDescricao] = useState("");
  const [pago, setPago] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        `${urlApi}/oneEvento/${id}`
      );
      setData(response.data);
      setLoading(true);
      setDescricao(
        response.data.descricao_pagamento == "null"
          ? ""
          : response.data.descricao_pagamento
      );
      setPago(response.data.pago)
      console.log(response.data.pago)
    }
    fetchData();
  }, []);

  const handleDescricaoPagamento = async (event) => {
    setDescricao(event.target.value);
    const value = event.target.value == "" ? null : event.target.value;
    const response = await axios.put(
      `${urlApi}/setDescricao/${id}/${value}`
    );
  };

  const checkPagamento = async (check) => {
    setPago(check.target.checked)
    const response = await axios.put(`${urlApi}/concluirPagamento/${id}/${check.target.checked}`)
  }

  return (
    <div className="flex justify-center">
      <Card className="h-full w-[95vw] bg-[#404040] m-5 pl-10 pr-10">
        {loading && (
          <>
            <div className="mt-5">
              <div className=" flex px-4 sm:px-0 justify-between">
                <div>
                  <h3 className="text-base font-semibold leading-7 text-white">
                    {data.nome_evento}
                  </h3>
                  {data && data.ativo ? (
                    <p className="mt-1 w-[65px] text-sm leading-6 text-gray-300">
                      <Chip color="green" value="Ativo" />
                    </p>
                  ) : (
                    <p className="mt-1 w-[85px] text-sm leading-6 text-gray-300">
                      <Chip color="red" value="Desativo" />
                    </p>
                  )}
                </div>

                <div className="flex">
                  <div className="flex flex-col">
                    <h3 className="text-base font-semibold leading-7 text-white">
                      Valor a ser pago: R${data.preco}
                    </h3>
                    <input
                      type="text"
                      value={descricao}
                      onChange={handleDescricaoPagamento}
                      placeholder="Descrição pagamento"
                      class="border-b-2 border-white outline-none bg-transparent text-white"
                    />
                  </div>

                  <Checkbox
                    label={
                      <Typography color="white">Pagamento Concluído</Typography>
                    }
                    checked={pago}
                    onChange={checkPagamento}
                    className="text-white"
                    color="orange"
                  />
                </div>
              </div>
              <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="flex justify-between">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-2 w-1/3 sm:px-0 ">
                      <dt className="text-sm font-medium leading-6 text-white ">
                        Nome da contratante
                      </dt>
                      <dd className="text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {data.nome_contratante}
                      </dd>
                    </div>
                    <div className="px-4 py-6  sm:grid sm:grid-cols-2 w-1/3 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-white">
                        Nome da aniversariante
                      </dt>
                      <dd className="text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {data.nome_aniversariante && "Não tem"}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-2 w-1/3 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-white">
                        Idade do aniversariante
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {data.idade_aniversariante == ""
                          ? "Não tem"
                          : data.idade_aniversariante}
                      </dd>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-2 w-1/3 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-white">
                        Telefone
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {data.telefone}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-2 w-1/3 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-white">
                        Endereço
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {data.endereco}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-2  w-1/3 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-white">
                        Data do evento
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {data.data_evento}
                      </dd>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-2 w-1/3 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-white">
                        Horário inicial
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {data.horario_elenco}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-2 w-1/3 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-white">
                        Horário final
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {data.horario_final}
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-2  w-1/3 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-white">
                        Horário do parabéns
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {data.horario_bolo == ""
                          ? "Não tem"
                          : data.horario_bolo}
                      </dd>
                    </div>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-white">
                      Descrição
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                      {data.descricao}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-white">
                      Membros de elenco
                    </dt>
                    <dd className="flex justify-between lg:justify-start flex-wrap lg:gap-5 mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
                      {data.colaboradoresAndamento.map((colaborador) => {
                        return (
                          <AvatarColaboradores
                            key={colaborador.idColaborador}
                            id={colaborador.idColaborador}
                            idEvento={id}
                            roteiro={
                              colaborador.roteiro &&
                              colaborador.roteiro.split("-")[1]
                            }
                          />
                        );
                      })}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-white">
                      Personagens
                    </dt>
                    <dd className="flex justify-between lg:justify-start flex-wrap lg:gap-5 mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
                      {data.fantasiasAndamento.map((fantasia) => {
                        return <AvatarFantasia id={fantasia.idFantasia} />;
                      })}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </>
        )}
        {data && data.ativo ? (
          <div className="flex flex-col lg:flex-row  gap-5 mb-5 justify-end">
            <Button
              onClick={() => {
                Swal.fire({
                  background: "#1e1e1e",
                  color: "#fff",
                  title: "Você tem certeza?",
                  text: "Não poderá reverter isso!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#006400",
                  cancelButtonColor: "#d33",
                  cancelButtonText: "Cancelar",
                  confirmButtonText: "Sim, deletar!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    if (DeleteEvento(id)) {
                      Swal.fire({
                        background: "#1e1e1e",
                        color: "#fff",
                        title: "Deletado!",
                        html: "Seu evento foi deletado com sucesso.",
                        icon: "success",
                      });
                      setTimeout(() => {
                        navigate("/");
                      }, 1500);
                    } else {
                      Swal.fire({
                        background: "#1e1e1e",
                        color: "#fff",
                        title: "Algo deu errado!",
                        html: "Tente novamente mais tarde.",
                        icon: "error",
                      });
                    }
                  }
                });
              }}
              color="red"
            >
              Apagar evento
            </Button>
            <DialogEditEventos id={id} />
            <Button
              onClick={() => {
                Swal.fire({
                  background: "#1e1e1e",
                  color: "#fff",
                  title: "Encerrar evento?",
                  text: "Que bom que você ja o finalizou",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#006400",
                  cancelButtonColor: "#d33",
                  cancelButtonText: "Cancelar",
                  confirmButtonText: "Sim, encerrar!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    const response = EncerrarEvento(id);
                    if (EncerrarEvento(id)) {
                      Swal.fire({
                        background: "#1e1e1e",
                        color: "#fff",
                        title: "Encerrado!",
                        html: "Seu evento foi encerrado com sucesso.",
                        icon: "success",
                      });
                      setTimeout(() => {
                        navigate("/");
                      }, 1500);
                    } else {
                      Swal.fire({
                        background: "#1e1e1e",
                        color: "#fff",
                        title: "Algo deu errado!",
                        html: "Tente novamente mais tarde.",
                        icon: "error",
                      });
                    }
                  }
                });
              }}
              color="green"
            >
              {" "}
              Encerrar evento{" "}
            </Button>
          </div>
        ) : null}
      </Card>
    </div>
  );
}
