import { Avatar, Button, Input, Typography } from "@material-tailwind/react";
import MenuFantasia from "./MenuFantasia";
import MenuFuncao from "./MenuFuncao";
import { useEffect, useState } from "react";
import DeleteAFandamento from "../../../../../controller/eventos/DeleteAFandamento";
import useEventoContext from "../../../../../hook/useEventoContext";

export function CardAvatar({
  id,
  idEvento,
  content,
  imagem,
  provided,
  descricao,
  handleDescricao,
  isMonitor,
  fantasia,
  isColabSelect,
  isEdit
}) {
  const { itemsColab, setItemsColab, selectedColab, setSelectedColab, setSelectedFantasia,
    setItemsFantasia, itemsFantasia, selectedFantasia, } = useEventoContext();
  const [isAtor, setIsAtor] = useState(!isMonitor);
  
  const removerColaboradorPorId = (id) => {
    const novaListaColaboradores = selectedColab.filter((colaborador) => {
      if (colaborador.id == id) {
        setItemsColab([...itemsColab, colaborador])

      }
      return colaborador.id !== id
    });
    setSelectedColab(novaListaColaboradores);
  };

  const removerFantasiaDoAtor = (id) => {
    setSelectedColab((prevData) => {
      return prevData.map((colab) => {
        if (colab.id === id) {
          return { ...colab, idFantasia: null, fantasia: null };
        } else {
          return { ...colab };
        }
      });
    });
  }

  const removerFantasiaPorId = (id) => {
    const novaListaFantasia = selectedFantasia.filter((fantasia) => {
      if (fantasia.id == id) {
        setItemsFantasia([...itemsFantasia, fantasia])

      }
      return fantasia.id !== id
    });
    setSelectedFantasia(novaListaFantasia);
  };

  const handleIsAtor = (funcao) => {
    if (funcao === "Ator") {
      setIsAtor(true);
    } else {
      setIsAtor(false)
    }

  }

  return (
    <div
      className="flex flex-col  bg-[#454545] rounded-[20px]"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="flex items-start gap-4 ">
        <Avatar src={imagem} alt="avatar" className="z-40" />
        <div>
          <Typography color="white">{content}</Typography>
          <Typography
            variant="small"
            className="font-normal text-gray-400 truncate"
          >
            {descricao}
          </Typography>
        </div>
      </div>
      {!isColabSelect && isEdit && <Button onClick={() => {
        removerFantasiaPorId(id);
        DeleteAFandamento(id, idEvento, false)
      }
      } color="red">Remover Fantasia</Button>}
      {isColabSelect && (
        <div className="flex flex-col">
          <MenuFuncao id={id} handler={handleIsAtor} removerFantasia={removerFantasiaDoAtor}  funcao={isAtor != null ? isAtor ? "Ator" : "Coordenador" : "Função"} />
          {isAtor && <MenuFantasia id={id} fantasia={fantasia}  />}
      
          {isEdit && <Button onClick={() => {
            removerColaboradorPorId(id);
            removerFantasiaDoAtor(id)
            DeleteAFandamento(id, idEvento, true)
          }
          } color="red">Remover membro de elenco</Button>}

        </div>
      )}
    </div>
  );
}
