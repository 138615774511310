import { useContext } from "react"
import EventoContext from "../context/EventoContext"

const useEventoContext = () => {
    const context = useContext(EventoContext);

    if(context === undefined) {
        throw new Error("Não está dentro do contexto")
    }

    return context;
}
export default useEventoContext;