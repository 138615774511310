import axios from "axios";
import urlApi from "../../urlApi";

const CreateFantasia = (
    nome, 
    descricao, 
    listImagesFantasias,
    listImagesMaquiagem,
    musicaTema,
    localDaPeca,
    videos,
    videoMaquiagem) => {
    const url = `${urlApi}/createFantasia`;
    
    const data = {
        "nome": nome,
        "descricao": descricao,
        "base64ImageFantasia": listImagesFantasias,
        "base64ImageMaquiagem": listImagesMaquiagem,
        "musica_tema": musicaTema,
        "videos": videos,
        "video_maquiagem": videoMaquiagem,
        "local": localDaPeca
    };
    axios.post(url, data)
    .then(response => {
        console.log('Resposta:', response.data);
    })
    .catch(error => {
        console.error('Erro:', error);
    });
}

export default CreateFantasia;