import { Input, Textarea, Typography } from "@material-tailwind/react";
import React from "react";
import ReactInputMask from "react-input-mask";
import useEventoContext from "../../../../hook/useEventoContext";

export default function Step2() {
  const {
    handleNomeAniversarianteChange,
    handleIdadeAniversarianteChange,
    handleHorarioDoBoloChange,
    handleDescricaoChange,
    idadeAniversariante,
    horarioDoBolo,
    descricao,
    nomeAniversariante,
  } = useEventoContext();

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-5">
        <div className="w-full lg:w-96">
          <Input
            onChange={handleNomeAniversarianteChange}
            value={nomeAniversariante}
            label="Nome do aniversariante"
            className="text-white"
            color="orange"
          />
        </div>
        <section className="flex gap-2 items-center">
          <Typography color="white">idade do aniversariante:</Typography>
          <div className="w-16 lg:w-16">
            <ReactInputMask
              value={idadeAniversariante}
              onChange={handleIdadeAniversarianteChange}
              className="peer h-full w-full rounded-[7px] placeholder:text-blue-gray-500 border border-blue-gray-200  bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              mask="99"
            />
          </div>
        </section>
        <section className="flex gap-2 items-center">
          <Typography color="white">Horário do bolo:</Typography>
          <div className="w-16 lg:w-16">
            <ReactInputMask
              value={horarioDoBolo}
              onChange={handleHorarioDoBoloChange}
              className="peer h-full w-full rounded-[7px] placeholder:text-blue-gray-500 border border-blue-gray-200  bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-orange-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              mask="99:99"
            />
          </div>
        </section>
      </div>
      <Textarea
        onChange={handleDescricaoChange}
        value={descricao}
        className="h-10 text-white"
        label="Descrição"
        color="orange"
      />
    </>
  );
}
