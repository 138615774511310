import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "./logo-leonardo.png";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import { Badge } from "@material-tailwind/react";
import urlApi from "../urlApi";

const userNavigation = [
  {
    name: "Sair",
    href: "/login",
    click: () => {
      localStorage.removeItem("token");
    },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Cabecalho() {
  const location = useLocation();
  const rota = location.pathname;
  const [dataUser, setDataUser] = useState();
  const id = localStorage.getItem("id");
  const [qntEventos, setQntEventos] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${urlApi}/colaborador/${id}`
        );
        const responseQnt = await axios.get(`${urlApi}/eventosPendentes`)
        setQntEventos(responseQnt.data.quantidade)
        setDataUser(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const navigation = [
    { name: "Home", href: "/", current: rota === "/" ? true : false },
    {
      name: "Membros de Elenco",
      href: "/colaboradores",
      current: rota === "/colaboradores" ? true : false,
    },
    {
      name: "Personagens",
      href: "/fantasias",
      current: rota === "/fantasias" ? true : false,
    },
    {
      name: "Eventos finalizados",
      href: "/eventosFinalizados",
      current: rota === "/eventosFinalizados" ? true : false,
    },
    {
      name: "Pagamentos Pendentes",
      href: "/eventosPendentes",
      current: rota === "/eventosPendentes" ? true : false,
    },
  ];



  return (
    <>
      {rota === "/login" ? (
        <div></div>
      ) : (
        <Disclosure as="nav" className="bg-[#ddb900]">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-12 w-18"
                        src={logo}
                        alt="Leonardo Producoes"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) =>
                          item.href == "/eventosPendentes" ? (
                            <Badge color="red"  content={qntEventos}>
                              <Link
                                key={item.name}
                                as="a"
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-orange-700 text-white"
                                    : "text-white hover:bg-orange-700 hover:text-white",
                                  "block rounded-md px-3 py-2 text-base font-medium"
                                )}
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </Link>
                            </Badge>
                          ) : (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-orange-700 text-white px-4 text-base"
                                  : "text-white hover:bg-orange-500 hover:text-white",
                                "rounded-md px-3 py-2  font-medium text-lg"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Abrir menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={`${urlApi}/media/colaboradores/${id}-0.jpg`}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-gray-900 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? "bg-orange-700" : "",
                                      "block px-4 py-2 text-sm text-white bg-gray-900"
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-orange-700 p-2 text-white hover:bg-orange-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) =>
                    item.href == "/eventosPendentes" ? (
                      <Badge color="red ">
                        <Link
                       
                          key={item.name}
                          as="a"
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-orange-700 text-white"
                              : "text-white hover:bg-orange-700 hover:text-white",
                            "block rounded-md px-3 py-2 text-base font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      </Badge>
                    ) : (
                      <Link
                        key={item.name}
                        as="a"
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-orange-700 text-white"
                            : "text-white hover:bg-orange-700 hover:text-white",
                          "block rounded-md px-3 py-2 text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    )
                  )}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={`${urlApi}/media/colaboradores/${id}.jpg`}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">
                        {dataUser && dataUser.nome}
                      </div>
                      <div className="text-sm font-medium leading-none text-white">
                        {dataUser && dataUser.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        onClick={item.click}
                        className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-orange-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
}
