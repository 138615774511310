import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import DialogViewFantasia from "./DialogViewFantasia";
import DialogCreateFantasia from "./DialogCreateFantasia";
import DialogEditFantasia from "./DialogEditFantasia";
import DialogConfirmDelete from "./DialogConfirmDelete";
import urlApi from "../../../urlApi";

const TABLE_HEAD = ["", ""];

export function TableFantasias() {
  const [data, setData] = useState([]);
  const [paginacao, setPaginacao] = useState(1);
  const [habilitarNext, setHabilitarNext] = useState(false);
  const [habilitarPrevious, setHabilitarPrevious] = useState(true);

  const fetchData = async (pagina) => {
    try {
      const response = await axios.get(
        `${urlApi}/fantasias/${pagina}`
      );
      if (data.length === 0 && pagina > 1) {
        setHabilitarNext(true);
      } else {
        setHabilitarNext(false);
      }
      if (pagina <= 1) {
        setHabilitarPrevious(true);
      } else {
        setHabilitarPrevious(false);
      }
      setData(response.data);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  const searchData = async (data) => {
    try {
      const response = await axios.get(
        `${urlApi}/searchFantasia/${data}`
      );
      setData(response.data);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  return (
    <Card className="h-full w-full bg-[#404040] ">
      <CardHeader
        floated={false}
        shadow={false}
        className="rounded-none bg-[#404040]"
      >
        <div className="mb-8 flex items-center justify-between gap-8 bg-[#404040]">
          <div>
            <Typography variant="h5" color="white">
              Personagem
            </Typography>
            <Typography color="white" className="mt-1 font-normal">
              Veja todos os seus personagens
            </Typography>
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row ">
            <DialogCreateFantasia />
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row bg-[#404040]">
          <div className="w-full md:w-72 ">
            <Input
              onChange={(data) => searchData(data.target.value)}
              label="Pesquisar"
              color="orange"
              className="text-white"
              icon={<MagnifyingGlassIcon className="h-5 w-5" color="white" />}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-auto sm:overflow-scroll lg:overflow-y-auto lg:overflow-x-auto px-0">
        <table className="mt-4 w-full max-w-[100vw] table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-[#404040] p-4"
                >
                  <Typography
                    variant="small"
                    color="white"
                    className="font-normal leading-none "
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(
              ({ id, nome, imagens, descricao, imagem_maquiagem }, index) => {
                const listImagens = imagens.split(",");
                listImagens.splice(0, 1);
                const listImagensMaquiagem = imagem_maquiagem.split(",");
                listImagensMaquiagem.splice(0, 1);

                return (
                  <tr key={id}>
                    <div className="ml-3">
                      <Typography color={"white"} className="m-2 font-semibold">
                        {nome}
                      </Typography>
                      <div className="ml gap-3 overflow-x-auto flex">
                        {listImagens.map((imagem) => {
                          return (
                            <DialogViewFantasia
                              id={id}
                              nome={nome}
                              imagem={imagem}
                              listImagens={listImagens}
                              listImagensMaquiagem={listImagensMaquiagem}
                              descricao={descricao}
                            />
                          );
                        })}
                        {listImagensMaquiagem.map((imagem) => {
                          return (
                            <DialogViewFantasia
                              id={id}
                              nome={nome}
                              imagem={imagem}
                              listImagens={listImagens}
                              listImagensMaquiagem={listImagensMaquiagem}
                              descricao={descricao}
                              isEvento={false}
                            />
                          );
                        })}
                      </div>
                      <div className="gap-1 flex">
                        <DialogEditFantasia id={id} />
                        <DialogConfirmDelete fetchData={fetchData} id={id} />
                      </div>
                    </div>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-end border-t border-blue-gray-50 p-4">
        <div className="flex gap-2">
          <Button
            disabled={habilitarPrevious}
            variant="outlined"
            color="white"
            size="sm"
            onClick={async () => {
              setPaginacao(paginacao - 1);
              fetchData(paginacao - 1);
            }}
          >
            Anterior
          </Button>
          <Button
            disabled={habilitarNext}
            variant="outlined"
            color="white"
            size="sm"
            onClick={() => {
              setPaginacao(paginacao + 1);
              fetchData(paginacao + 1);
            }}
          >
            Próximo
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
