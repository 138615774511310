import React, {useState} from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Tooltip,
  IconButton,
  Spinner
} from "@material-tailwind/react";
import { TrashIcon } from "@heroicons/react/24/solid";
import DeleteFantasia from "../../../controller/fantasias/DeleteFantasia";
 
export default function DialogConfirmDelete({id, fetchData}) {
    const [open, setOpen] = useState(false);
    const [atualizar, setAtualizar] = useState(false);
    const handleOpen = () => setOpen(!open);

  return (
    <>
    <Tooltip content="Deletar Personagem">
    <IconButton variant="text" color="red" onClick={handleOpen}>
        <TrashIcon className="h-5 w-5" />
    </IconButton>
    </Tooltip>
    {open &&  
    <Dialog open={open} size="xs" handler={handleOpen} className='bg-[#1e1e1e]'>
        <DialogHeader>⚠️</DialogHeader>
        <DialogBody divider className={`text-white flex ${atualizar ? "justify-center" : "justify-start"}`}>
            {atualizar ? <Spinner color="amber" className="h-10 w-10 flex"/> : 
            "Tem certeza que deseja excluir este personagem?"}
        </DialogBody>
        <DialogFooter>
        <Button
            variant="text"
            color="red"
            onClick={() => handleOpen(null)}
            className="mr-1"
        >
            <span>Cancel</span>
        </Button>
        <Button
            variant="gradient"
            color="green"
            onClick={() => {
                DeleteFantasia(id);
                setAtualizar(!atualizar)
                setTimeout(() => {
                    fetchData(1);
                    setOpen(!open)
                }, 2000)
                
            }}
        >
            <span>Confirm</span>
        </Button>
        </DialogFooter>
    </Dialog>}
    </>
   
  )
}
